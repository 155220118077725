import { Box, Divider, Grid, InputLabel, Stack } from '@mui/material';
import React from 'react';
import AroundTheClockPlayer from '../../../models/aroundtheclock/AroundTheClockPlayer';
import DartsThrow from '../../../models/common/DartsThrow';
import './aroundTheClockPlayer.css';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';


type AroundTheClockPlayerProps = {
    player: AroundTheClockPlayer;
    active: boolean;
    dartsThrow: DartsThrow;
}

const AroundTheClockPlayerComponent = ({ player, active, dartsThrow }: AroundTheClockPlayerProps) => {

    return (
        <div>
            <Grid item xs={16}>
                <div className={"atc-player-main-container"}>
                    <Box sx={{ border: 1 }}>
                        <div className={active ? "atc-active-player" : ""}>
                            {/* <div> */}
                            <div className="atc-player-container-with-highlighting">
                                <div className="one-item">
                                    <Stack
                                        sx={{ width: 230 }}
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Box width={30}>
                                            {
                                                // player.currentLeg().started && <FlagIcon sx={{ fontSize: 30 }} />
                                                // <FlagIcon sx={{ fontSize: 30 }} />
                                            }
                                        </Box>
                                        <Box>
                                            {/* style={{ textAlign: "center", overflow: "hidden", textOverflow: "ellipsis", width: '9rem', backgroundColor: '#85c4ff' }} */}
                                            <InputLabel sx={{ fontSize: 30, color: 'black', width: '14rem' }}>{player.name}</InputLabel>
                                        </Box>
                                        <Box width={30}>
                                            {
                                                // placeholder
                                            }
                                        </Box>
                                    </Stack>
                                </div>
                            </div>
                            <Divider />
                            <div className="atc-player-container">
                                <InputLabel sx={{ fontSize: "60px", color: 'black' }}>
                                    {
                                        (player.currentLeg()?.currentScore !== 25 && player.currentLeg()?.currentScore)
                                    }
                                    {
                                        player.currentLeg()?.currentScore === 25 && player.currentLeg()?.amountOfBulls === 0 && <RadioButtonUncheckedIcon sx={{ fontSize: 60, marginBottom: -1 }} />
                                    }
                                    {
                                        player.currentLeg()?.currentScore === 25 && player.currentLeg()?.amountOfBulls === 1 && <RadioButtonCheckedIcon sx={{ fontSize: 60, marginBottom: -1 }} />
                                    }
                                    {
                                        player.currentLeg()?.currentScore === 25 && player.currentLeg()?.amountOfBulls === 2 && <div><EmojiEventsIcon sx={{ fontSize: 60, marginBottom: -1 }} /> {player.currentLeg().position}.</div>
                                    }
                                </InputLabel>
                            </div>
                            <Divider />
                            <div className="atc-player-container">
                                <Stack
                                    sx={{ width: 230 }}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-evenly"
                                    divider={<Divider orientation="vertical" flexItem />}
                                // margin={-0.5}
                                >
                                    <InputLabel sx={{ fontSize: 40, color: 'black' }}>{dartsThrow?.dart1?.fieldType.concat(dartsThrow?.dart1?.number.toString()) || '-'}</InputLabel>
                                    <InputLabel sx={{ fontSize: 40, color: 'black' }}>{dartsThrow?.dart2?.fieldType.concat(dartsThrow?.dart2?.number.toString()) || '-'}</InputLabel>
                                    <InputLabel sx={{ fontSize: 40, color: 'black' }}>{dartsThrow?.dart3?.fieldType.concat(dartsThrow?.dart3?.number.toString()) || '-'}</InputLabel>
                                </Stack>
                            </div>
                            <Divider />
                        </div>
                    </Box>
                </div>
            </Grid >
        </div >
    )
}

export default AroundTheClockPlayerComponent