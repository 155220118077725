import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Alert, Avatar, Box, Button, Container, createTheme, CssBaseline, Grid, Link, TextField, ThemeProvider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorResponseHandler from '../../helper/ErrorResponseHandler';
import AuthenticationRequest from '../../models/AuthenticationRequest';
import AuthService from '../../services/AuthService';
import CopyrightComponent from '../footer/CopyrightComponent';
import { passwordStrength } from 'check-password-strength'



const theme = createTheme();

const SignUpComponent = () => {

    const [errorMessage, setErrorMessage] = useState<string>(null);
    const [isEmailValid, setIsEmailValid] = useState<boolean>();
    const [isPasswordSecure, setIsPasswordSecure] = useState<boolean>();

    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const registration: AuthenticationRequest = {
            email: data.get('email'),
            password: data.get('password')
        };

        signUp(registration);
    };

    const signUp = async (registration: AuthenticationRequest) => {
        await AuthService.signUp(registration)
            .then(() => {
                navigate("/login");
                setErrorMessage(null);
            }).catch((error) => {
                setErrorMessage(ErrorResponseHandler.getErrorMessage(error, "Registration failed. Email already used."));
            });
    };

    const handleEmailValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        setIsEmailValid(regex.test(e.target.value));
    }

    const handlePasswordValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
        const strength = passwordStrength(e.target.value).id
        console.log(strength)
        setIsPasswordSecure(strength >= 1);
    }

    return (
        <div>
            <Container maxWidth='xs'>
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                SIGN UP
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            onChange={handleEmailValidation}
                                            error={isEmailValid != null && !isEmailValid}
                                            helperText={(isEmailValid != null && !isEmailValid) && "Invalid email address"}
                                            FormHelperTextProps={{
                                                style: { fontSize: 20 }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            onChange={handlePasswordValidation}
                                            error={isPasswordSecure != null && !isPasswordSecure}
                                            helperText={(isPasswordSecure != null && !isPasswordSecure) && "Password too weak"}
                                            FormHelperTextProps={{
                                                style: { fontSize: 20 }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <div>
                                    {
                                        errorMessage != null &&
                                        <Alert severity="error">{errorMessage}</Alert>
                                    }
                                </div>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={!isEmailValid || !isPasswordSecure}
                                >
                                    Sign Up
                                </Button>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link href="/login" variant="body2">
                                            Already have an account? Log in
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <CopyrightComponent sx={{ mt: 5 }} />
                    </Container>
                </ThemeProvider>
            </Container>
        </div>
    );
}

export default SignUpComponent