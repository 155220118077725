import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import AuthService from '../../services/AuthService';
import { loginState, playersState } from '../atoms';
import HomeButtonComponent from './HomeButtonComponent';

const gamePage = 'GAME';
const playersPage = 'PLAYERS';
const pages = [gamePage, playersPage];

const HeaderComponent = () => {
    
    const navigate = useNavigate();

    const [loginStatus, setLoginStatus] = useRecoilState(loginState);
    const setPlayers = useSetRecoilState(playersState);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    useEffect(() => {
        setLoginStatus(AuthService.isAccessTokenAvailable());
    }, [loginStatus, setLoginStatus]);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const button: HTMLButtonElement = e.currentTarget;

        switch (button.name) {
            case gamePage:
                navigate('/')
                break;
            case playersPage:
                navigate('/players')
                break;
            default:
                break;
        }

        setAnchorElNav(null);
        setAnchorElUser(null);
    };

    const onMenuItemClick = (pageName: string) => {
        switch (pageName) {
            case gamePage:
                navigate('/')
                break;
            case playersPage:
                navigate('/players')
                break;
            default:
                break;
        }

        setAnchorElNav(null);
        setAnchorElUser(null);
    };

    const onUserMenuItemClick = (pageName: string) => {
        switch (pageName) {
            case "LOG IN":
                navigate('/login')
                break;
            case "SIGN UP":
                navigate('/signup')
                break;
            case "LOG OUT":
                AuthService.logout();
                setLoginStatus(false);
                setPlayers([]);
                navigate('/')
                window.location.reload();
                break;
            default:
                break;
        }
    };

    const getUserSettings = (): string[] => {
        if (AuthService.isAccessTokenAvailable() && loginStatus) {
            return ['LOG OUT'];
        }

        return ['LOG IN', 'SIGN UP'];
    }


    const getUserMenuText = (): string => {
        if (loginStatus) {
            return AuthService.getEmailAddress();
        }

        return ""
    }

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{height: 36}}>

                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none'
                        }}
                    >
                        <HomeButtonComponent />
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {
                                pages.map((page) => (
                                    <MenuItem key={page} onClick={() => onMenuItemClick(page)}>
                                        <Typography sx={{ fontSize: 30 }} textAlign="center">{page}</Typography>
                                    </MenuItem>
                                ))
                            }
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <HomeButtonComponent />
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                name={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 1, color: 'white', display: 'block', fontSize: 30 }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/3.jpg" /> */}
                                {getUserMenuText()}
                                <AccountCircleIcon sx={{ fontSize: 50 }}></AccountCircleIcon>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseNavMenu}
                        >
                            {getUserSettings().map((setting) => (
                                <MenuItem key={setting} onClick={() => onUserMenuItemClick(setting)}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}

                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default HeaderComponent;
