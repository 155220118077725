
class DoubleOutOption {
    static readonly C_170 = new DoubleOutOption(170, 3, "T20-T20-DB", null, null);
    static readonly C_167 = new DoubleOutOption(167, 3, "T20-T19-DB", null, null);
    static readonly C_164 = new DoubleOutOption(164, 3, "T20-T18-DB", "T19-T19-DB", null);
    static readonly C_161 = new DoubleOutOption(161, 3, "T20-T17-DB", null, null);
    static readonly C_160 = new DoubleOutOption(160, 3, "T20-T20-D20", null, null);
    static readonly C_158 = new DoubleOutOption(158, 3, "T20-T20-D19", null, null);
    static readonly C_157 = new DoubleOutOption(157, 3, "T20-T19-D20", null, null);
    static readonly C_156 = new DoubleOutOption(156, 3, "T20-T20-D18", null, null);
    static readonly C_155 = new DoubleOutOption(155, 3, "T20-T19-D19", null, null);
    static readonly C_154 = new DoubleOutOption(154, 3, "T20-T18-D20", null, null);
    static readonly C_153 = new DoubleOutOption(153, 3, "T20-T19-D18", null, null);
    static readonly C_152 = new DoubleOutOption(152, 3, "T20-T20-D16", null, null);
    static readonly C_151 = new DoubleOutOption(151, 3, "T20-T17-D20", "T19-T18-D20", null);
    static readonly C_150 = new DoubleOutOption(150, 3, "T20-T18-D18", "T19-T19-D18", null);
    static readonly C_149 = new DoubleOutOption(149, 3, "T20-T19-D16", null, null);
    static readonly C_148 = new DoubleOutOption(148, 3, "T20-T20-D14", "T19-T17-D20", null);
    static readonly C_147 = new DoubleOutOption(147, 3, "T20-T17-D18", "T19-T18-D18", null);
    static readonly C_146 = new DoubleOutOption(146, 3, "T20-T18-D16", "T19-T19-D16", null);
    static readonly C_145 = new DoubleOutOption(145, 3, "T20-T19-D14", null, null);
    static readonly C_144 = new DoubleOutOption(144, 3, "T20-T20-D12", null, null);
    static readonly C_143 = new DoubleOutOption(143, 3, "T20-T17-D16", "T19-T18-D16", null);
    static readonly C_142 = new DoubleOutOption(142, 3, "T20-T14-D20", "T19-T19-D14", null);
    static readonly C_141 = new DoubleOutOption(141, 3, "T20-T19-D12", null, null);
    static readonly C_140 = new DoubleOutOption(140, 3, "T20-T20-D10", null, null);
    static readonly C_139 = new DoubleOutOption(139, 3, "T20-T13-D20", "T20-T19-D11", null);
    static readonly C_138 = new DoubleOutOption(138, 3, "T20-T18-D12", "T19-T19-D12", null);
    static readonly C_137 = new DoubleOutOption(137, 3, "T20-T19-D10", null, null);
    static readonly C_136 = new DoubleOutOption(136, 3, "T20-T20-D8", null, null);
    static readonly C_135 = new DoubleOutOption(135, 3, "T20-T17-D12", "SB-T20-DB", null);
    static readonly C_134 = new DoubleOutOption(134, 3, "T20-T16-D13", null, null);
    static readonly C_133 = new DoubleOutOption(133, 3, "T20-T19-D8", null, null);
    static readonly C_132 = new DoubleOutOption(132, 3, "T20-T16-D12", "SB-T19-DB", null);
    static readonly C_131 = new DoubleOutOption(131, 3, "T19-T14-D16", "T20-T13-D16", null);
    static readonly C_130 = new DoubleOutOption(130, 3, "T20-T20-D5", null, null);
    static readonly C_129 = new DoubleOutOption(129, 3, "T19-T16-D12", "T20-T19-D6", null);
    static readonly C_128 = new DoubleOutOption(128, 3, "T18-T14-D16", "T20-T18-D7", null);
    static readonly C_127 = new DoubleOutOption(127, 3, "T20-T17-D8", null, null);
    static readonly C_126 = new DoubleOutOption(126, 3, "T19-T19-D6", null, null);
    static readonly C_125 = new DoubleOutOption(125, 3, "T18-T19-D7", "T20-T15-D10", null);
    static readonly C_124 = new DoubleOutOption(124, 3, "T20-T14-T11", null, null);
    static readonly C_123 = new DoubleOutOption(123, 3, "T19-T16-D9", null, null);
    static readonly C_122 = new DoubleOutOption(122, 3, "T18-T18-D7", null, null);
    static readonly C_121 = new DoubleOutOption(121, 3, "T20-T11-D14", "T17-T20-D5", null);
    static readonly C_120 = new DoubleOutOption(120, 3, "T20-20-D20", null, null);
    static readonly C_119 = new DoubleOutOption(119, 3, "T19-T12-D13", null, null);
    static readonly C_118 = new DoubleOutOption(118, 3, "T20-18-D20", null, null);
    static readonly C_117 = new DoubleOutOption(117, 3, "T19-20-D20", "T20-17-D20", null);
    static readonly C_116 = new DoubleOutOption(116, 3, "T19-19-D20", "T20-16-D20", null);
    static readonly C_115 = new DoubleOutOption(115, 3, "T20-15-D20", "T19-18-D20", null);
    static readonly C_114 = new DoubleOutOption(114, 3, "T19-17-D20", "T20-14-D20", null);
    static readonly C_113 = new DoubleOutOption(113, 3, "T19-16-D20", null, null);
    static readonly C_112 = new DoubleOutOption(112, 3, "T20-T12-D8", null, null);
    static readonly C_111 = new DoubleOutOption(111, 3, "T19-14-D20", "T20-11-D20", null);
    static readonly C_110 = new DoubleOutOption(110, 2, "T20-T10-D20", "T20-DB", null);
    static readonly C_109 = new DoubleOutOption(109, 3, "T20-9-D20", null, null);
    static readonly C_108 = new DoubleOutOption(108, 3, "T20-16-D16", "T20-8-D20", null);
    static readonly C_107 = new DoubleOutOption(107, 2, "T19-T10-D10", "T19-DB", null);
    static readonly C_106 = new DoubleOutOption(106, 3, "T20-T10-D8", null, null);
    static readonly C_105 = new DoubleOutOption(105, 3, "T20-13-D16", null, null);
    static readonly C_104 = new DoubleOutOption(104, 2, "T19-15-D16", "T18-DB", null);
    static readonly C_103 = new DoubleOutOption(103, 3, "T19-6-D20", "T19-10-D18", null);
    static readonly C_102 = new DoubleOutOption(102, 3, "T20-10-D16", "T20-6-D18", null);
    static readonly C_101 = new DoubleOutOption(101, 2, "T20-9-D16", "T17-DB", null);
    static readonly C_100 = new DoubleOutOption(100, 2, "T20-D20", null, null);
    static readonly C_99 = new DoubleOutOption(99, 3, "T19-10-D16", "T19-6-D18", null);
    static readonly C_98 = new DoubleOutOption(98, 2, "T20-D19", null, null);
    static readonly C_97 = new DoubleOutOption(97, 2, "T19-D20", null, null);
    static readonly C_96 = new DoubleOutOption(96, 2, "T20-D18", null, null);
    static readonly C_95 = new DoubleOutOption(95, 2, "T19-D19", "SB-T20-D5", null);
    static readonly C_94 = new DoubleOutOption(94, 2, "T18-D20", "SB-T19-D6", null);
    static readonly C_93 = new DoubleOutOption(93, 2, "T19-D18", "SB-T18-D7", null);
    static readonly C_92 = new DoubleOutOption(92, 2, "T20-D16", "SB-T17-D8", null);
    static readonly C_91 = new DoubleOutOption(91, 2, "T17-D20", "SB-T16-D9", null);
    static readonly C_90 = new DoubleOutOption(90, 2, "T20-D15", "T18-D18", null);
    static readonly C_89 = new DoubleOutOption(89, 2, "T19-D16", null, null);
    static readonly C_88 = new DoubleOutOption(88, 2, "T20-D14", null, null);
    static readonly C_87 = new DoubleOutOption(87, 2, "T17-D18", null, null);
    static readonly C_86 = new DoubleOutOption(86, 2, "T18-D16", null, null);
    static readonly C_85 = new DoubleOutOption(85, 2, "T15-D20", "T19-D14", null);
    static readonly C_84 = new DoubleOutOption(84, 2, "T20-D12", null, null);
    static readonly C_83 = new DoubleOutOption(83, 2, "T17-D16", null, null);
    static readonly C_82 = new DoubleOutOption(82, 2, "DB-D16", "SB-17-D20", "T14-D20");
    static readonly C_81 = new DoubleOutOption(81, 2, "T19-D12", "T15-D18", null);
    static readonly C_80 = new DoubleOutOption(80, 2, "T20-D10", "D20-D20", null);
    static readonly C_79 = new DoubleOutOption(79, 2, "T19-D11", "T13-D20", null);
    static readonly C_78 = new DoubleOutOption(78, 2, "T18-D12", null, null);
    static readonly C_77 = new DoubleOutOption(77, 2, "T19-D10", null, null);
    static readonly C_76 = new DoubleOutOption(76, 2, "T20-D8", "T16-D14", null);
    static readonly C_75 = new DoubleOutOption(75, 2, "T17-D12", "SB-DB", null);
    static readonly C_74 = new DoubleOutOption(74, 2, "T14-D16", null, null);
    static readonly C_73 = new DoubleOutOption(73, 2, "T19-D8", null, null);
    static readonly C_72 = new DoubleOutOption(72, 2, "T16-D12", "T20-D6", null);
    static readonly C_71 = new DoubleOutOption(71, 2, "T13-D16", null, null);
    static readonly C_70 = new DoubleOutOption(70, 2, "T18-D8", "T20-D5", null);
    static readonly C_69 = new DoubleOutOption(69, 2, "T19-D6", null, null);
    static readonly C_68 = new DoubleOutOption(68, 2, "T16-D10", "T20-D4", "T18-D7");
    static readonly C_67 = new DoubleOutOption(67, 2, "T9-D20", "T17-D8", null);
    static readonly C_66 = new DoubleOutOption(66, 2, "T10-D18", "T18-D6", "T16-D9");
    static readonly C_65 = new DoubleOutOption(65, 2, "T11-D16", "T19-D4", "T15-D10");
    static readonly C_64 = new DoubleOutOption(64, 2, "T16-D8", "T14-D11", null);
    static readonly C_63 = new DoubleOutOption(63, 2, "T13-D12", "T17-D6", "T13-D12");
    static readonly C_62 = new DoubleOutOption(62, 2, "T10-D16", "T12-D13", null);
    static readonly C_61 = new DoubleOutOption(61, 2, "T15-D8", "T7-D20", "T11-D14");
    static readonly C_60 = new DoubleOutOption(60, 2, "20-D20", null, null);
    static readonly C_59 = new DoubleOutOption(59, 2, "19-D20", null, null);
    static readonly C_58 = new DoubleOutOption(58, 2, "18-D20", null, null);
    static readonly C_57 = new DoubleOutOption(57, 2, "17-D20", null, null);
    static readonly C_56 = new DoubleOutOption(56, 2, "16-D20", "T16-D4", null);
    static readonly C_55 = new DoubleOutOption(55, 2, "15-D20", null, null);
    static readonly C_54 = new DoubleOutOption(54, 2, "14-D20", null, null);
    static readonly C_53 = new DoubleOutOption(53, 2, "13-D20", null, null);
    static readonly C_52 = new DoubleOutOption(52, 2, "12-D20", "20-D16", null);
    static readonly C_51 = new DoubleOutOption(51, 2, "11-D20", "19-D16", null);
    static readonly C_50 = new DoubleOutOption(50, 2, "10-D20", "18-D16", null);
    static readonly C_49 = new DoubleOutOption(49, 2, "9-D20", "17-D16", null);
    static readonly C_48 = new DoubleOutOption(48, 2, "8-D20", "16-D16", null);
    static readonly C_47 = new DoubleOutOption(47, 2, "7-D20", "15-D16", null);
    static readonly C_46 = new DoubleOutOption(46, 2, "6-D20", "10-D18", null);
    static readonly C_45 = new DoubleOutOption(45, 2, "5-D20", "13-D16", "19-D13");
    static readonly C_44 = new DoubleOutOption(44, 2, "4-D20", "12-D16", null);
    static readonly C_43 = new DoubleOutOption(43, 2, "3-D20", "11-D16", null);
    static readonly C_42 = new DoubleOutOption(42, 2, "2-D20", "10-D16", "6-D18");
    static readonly C_41 = new DoubleOutOption(41, 2, "1-D20", "9-D16", null);
    static readonly C_40 = new DoubleOutOption(40, 1, "D20", null, null);
    static readonly C_39 = new DoubleOutOption(39, 2, "7-D16", null, null);
    static readonly C_38 = new DoubleOutOption(38, 1, "D19", null, null);
    static readonly C_37 = new DoubleOutOption(37, 2, "5-D16", null, null);
    static readonly C_36 = new DoubleOutOption(36, 1, "D18", null, null);
    static readonly C_35 = new DoubleOutOption(35, 2, "3-D16", null, null);
    static readonly C_34 = new DoubleOutOption(34, 1, "D17", null, null);
    static readonly C_33 = new DoubleOutOption(33, 2, "1-D16", null, null);
    static readonly C_32 = new DoubleOutOption(32, 1, "D16", null, null);
    static readonly C_31 = new DoubleOutOption(31, 2, "15-D8", null, null);
    static readonly C_30 = new DoubleOutOption(30, 1, "D15", null, null);
    static readonly C_29 = new DoubleOutOption(29, 2, "13-D8", null, null);
    static readonly C_28 = new DoubleOutOption(28, 1, "D14", null, null);
    static readonly C_27 = new DoubleOutOption(27, 2, "11-D8", null, null);
    static readonly C_26 = new DoubleOutOption(26, 1, "D13", null, null);
    static readonly C_25 = new DoubleOutOption(25, 2, "9-D8", null, null);
    static readonly C_24 = new DoubleOutOption(24, 1, "D12", null, null);
    static readonly C_23 = new DoubleOutOption(23, 2, "7-D8", null, null);
    static readonly C_22 = new DoubleOutOption(22, 1, "D11", null, null);
    static readonly C_21 = new DoubleOutOption(21, 2, "5-D8", null, null);
    static readonly C_20 = new DoubleOutOption(20, 1, "D10", null, null);
    static readonly C_19 = new DoubleOutOption(19, 2, "3-D8", null, null);
    static readonly C_18 = new DoubleOutOption(18, 1, "D9", null, null);
    static readonly C_17 = new DoubleOutOption(17, 2, "1-D8", null, null);
    static readonly C_16 = new DoubleOutOption(16, 1, "D8", null, null);
    static readonly C_15 = new DoubleOutOption(15, 2, "7-D4", null, null);
    static readonly C_14 = new DoubleOutOption(14, 1, "D7", null, null);
    static readonly C_13 = new DoubleOutOption(13, 2, "5-D4", null, null);
    static readonly C_12 = new DoubleOutOption(12, 1, "D6", null, null);
    static readonly C_11 = new DoubleOutOption(11, 2, "3-D4", null, null);
    static readonly C_10 = new DoubleOutOption(10, 1, "D5", null, null);
    static readonly C_9 = new DoubleOutOption(9, 2, "1-D4", null, null);
    static readonly C_8 = new DoubleOutOption(8, 1, "D4", null, null);
    static readonly C_7 = new DoubleOutOption(7, 2, "3-D2", null, null);
    static readonly C_6 = new DoubleOutOption(6, 1, "D3", null, null);
    static readonly C_5 = new DoubleOutOption(5, 2, "1-D2", null, null);
    static readonly C_4 = new DoubleOutOption(4, 1, "D2", null, null);
    static readonly C_3 = new DoubleOutOption(3, 2, "1-D1", null, null);
    static readonly C_2 = new DoubleOutOption(2, 1, "D1", null, null);

    private constructor(
        public readonly score: number,
        public readonly dartsNeeded: number,
        public readonly option1: string,
        public readonly option2: string,
        public readonly option3: string) {
    }
}

export const DoubleOutOptions = new Map<number, DoubleOutOption>([
    [2, DoubleOutOption.C_2],
    [3, DoubleOutOption.C_3],
    [4, DoubleOutOption.C_4],
    [5, DoubleOutOption.C_5],
    [6, DoubleOutOption.C_6],
    [7, DoubleOutOption.C_7],
    [8, DoubleOutOption.C_8],
    [9, DoubleOutOption.C_9],
    [10, DoubleOutOption.C_10],
    [11, DoubleOutOption.C_11],
    [12, DoubleOutOption.C_12],
    [13, DoubleOutOption.C_13],
    [14, DoubleOutOption.C_14],
    [15, DoubleOutOption.C_15],
    [16, DoubleOutOption.C_16],
    [17, DoubleOutOption.C_17],
    [18, DoubleOutOption.C_18],
    [19, DoubleOutOption.C_19],
    [20, DoubleOutOption.C_20],
    [21, DoubleOutOption.C_21],
    [22, DoubleOutOption.C_22],
    [23, DoubleOutOption.C_23],
    [24, DoubleOutOption.C_24],
    [25, DoubleOutOption.C_25],
    [26, DoubleOutOption.C_26],
    [27, DoubleOutOption.C_27],
    [28, DoubleOutOption.C_28],
    [29, DoubleOutOption.C_29],
    [30, DoubleOutOption.C_30],
    [31, DoubleOutOption.C_31],
    [32, DoubleOutOption.C_32],
    [33, DoubleOutOption.C_33],
    [34, DoubleOutOption.C_34],
    [35, DoubleOutOption.C_35],
    [36, DoubleOutOption.C_36],
    [37, DoubleOutOption.C_37],
    [38, DoubleOutOption.C_38],
    [39, DoubleOutOption.C_39],
    [40, DoubleOutOption.C_40],
    [41, DoubleOutOption.C_41],
    [42, DoubleOutOption.C_42],
    [43, DoubleOutOption.C_43],
    [44, DoubleOutOption.C_44],
    [45, DoubleOutOption.C_45],
    [46, DoubleOutOption.C_46],
    [47, DoubleOutOption.C_47],
    [48, DoubleOutOption.C_48],
    [49, DoubleOutOption.C_49],
    [50, DoubleOutOption.C_50],
    [51, DoubleOutOption.C_51],
    [52, DoubleOutOption.C_52],
    [53, DoubleOutOption.C_53],
    [54, DoubleOutOption.C_54],
    [55, DoubleOutOption.C_55],
    [56, DoubleOutOption.C_56],
    [57, DoubleOutOption.C_57],
    [58, DoubleOutOption.C_58],
    [59, DoubleOutOption.C_59],
    [60, DoubleOutOption.C_60],
    [61, DoubleOutOption.C_61],
    [62, DoubleOutOption.C_62],
    [63, DoubleOutOption.C_63],
    [64, DoubleOutOption.C_64],
    [65, DoubleOutOption.C_65],
    [66, DoubleOutOption.C_66],
    [67, DoubleOutOption.C_67],
    [68, DoubleOutOption.C_68],
    [69, DoubleOutOption.C_69],
    [70, DoubleOutOption.C_70],
    [71, DoubleOutOption.C_71],
    [72, DoubleOutOption.C_72],
    [73, DoubleOutOption.C_73],
    [74, DoubleOutOption.C_74],
    [75, DoubleOutOption.C_75],
    [76, DoubleOutOption.C_76],
    [77, DoubleOutOption.C_77],
    [78, DoubleOutOption.C_78],
    [79, DoubleOutOption.C_79],
    [80, DoubleOutOption.C_80],
    [81, DoubleOutOption.C_81],
    [82, DoubleOutOption.C_82],
    [83, DoubleOutOption.C_83],
    [84, DoubleOutOption.C_84],
    [85, DoubleOutOption.C_85],
    [86, DoubleOutOption.C_86],
    [87, DoubleOutOption.C_87],
    [88, DoubleOutOption.C_88],
    [89, DoubleOutOption.C_89],
    [90, DoubleOutOption.C_90],
    [91, DoubleOutOption.C_91],
    [92, DoubleOutOption.C_92],
    [93, DoubleOutOption.C_93],
    [94, DoubleOutOption.C_94],
    [95, DoubleOutOption.C_95],
    [96, DoubleOutOption.C_96],
    [97, DoubleOutOption.C_97],
    [98, DoubleOutOption.C_98],
    [99, DoubleOutOption.C_99],
    [100, DoubleOutOption.C_100],
    [101, DoubleOutOption.C_101],
    [102, DoubleOutOption.C_102],
    [103, DoubleOutOption.C_103],
    [104, DoubleOutOption.C_104],
    [105, DoubleOutOption.C_105],
    [106, DoubleOutOption.C_106],
    [107, DoubleOutOption.C_107],
    [108, DoubleOutOption.C_108],
    [109, DoubleOutOption.C_109],
    [110, DoubleOutOption.C_110],
    [111, DoubleOutOption.C_111],
    [112, DoubleOutOption.C_112],
    [113, DoubleOutOption.C_113],
    [114, DoubleOutOption.C_114],
    [115, DoubleOutOption.C_115],
    [116, DoubleOutOption.C_116],
    [117, DoubleOutOption.C_117],
    [118, DoubleOutOption.C_118],
    [119, DoubleOutOption.C_119],
    [120, DoubleOutOption.C_120],
    [121, DoubleOutOption.C_121],
    [122, DoubleOutOption.C_122],
    [123, DoubleOutOption.C_123],
    [124, DoubleOutOption.C_124],
    [125, DoubleOutOption.C_125],
    [126, DoubleOutOption.C_126],
    [127, DoubleOutOption.C_127],
    [128, DoubleOutOption.C_128],
    [129, DoubleOutOption.C_129],
    [130, DoubleOutOption.C_130],
    [131, DoubleOutOption.C_131],
    [132, DoubleOutOption.C_132],
    [133, DoubleOutOption.C_133],
    [134, DoubleOutOption.C_134],
    [135, DoubleOutOption.C_135],
    [136, DoubleOutOption.C_136],
    [137, DoubleOutOption.C_137],
    [138, DoubleOutOption.C_138],
    [139, DoubleOutOption.C_139],
    [140, DoubleOutOption.C_140],
    [141, DoubleOutOption.C_141],
    [142, DoubleOutOption.C_142],
    [143, DoubleOutOption.C_143],
    [144, DoubleOutOption.C_144],
    [145, DoubleOutOption.C_145],
    [146, DoubleOutOption.C_146],
    [147, DoubleOutOption.C_147],
    [148, DoubleOutOption.C_148],
    [149, DoubleOutOption.C_149],
    [150, DoubleOutOption.C_150],
    [151, DoubleOutOption.C_151],
    [152, DoubleOutOption.C_152],
    [153, DoubleOutOption.C_153],
    [154, DoubleOutOption.C_154],
    [155, DoubleOutOption.C_155],
    [156, DoubleOutOption.C_156],
    [157, DoubleOutOption.C_157],
    [158, DoubleOutOption.C_158],
    [160, DoubleOutOption.C_160],
    [161, DoubleOutOption.C_161],
    [164, DoubleOutOption.C_164],
    [167, DoubleOutOption.C_167],
    [170, DoubleOutOption.C_170]

]);

export const getDoubleCheckout = (score: number): string[] => {
    const options = DoubleOutOptions.get(score);

    if (DoubleOutOptions.get(score) == null) {
        return null;
    }

    let result = [options.option1];

    if (options.option2 != null) {
        result.push(options.option2);
    }
    if (options.option3 != null) {
        result.push(options.option3);
    }

    return result;
}

export const getMinimumRequiredDartsForCheckout = (score: number): number => {
    const options = DoubleOutOptions.get(score);

    if (DoubleOutOptions.get(score) == null) {
        return null;
    }

    return options.dartsNeeded;
}

export const isDoubleOutPossible = (score: number): boolean => {
    return DoubleOutOptions.get(score) != null
}