import { Divider, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import React from 'react'
import Player from '../../../models/Player'


type StatsProps = {
    player: Player
    startingPoints: number
}

const StandardGameCurrentPlayerStatsComponent = ({ player, startingPoints }: StatsProps) => {

    return (
        <Box sx={{ width: 140, minWidth: 50 }}>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
            >
                <div style={{ textAlign: "center", overflow: "hidden", textOverflow: "ellipsis", width: '9rem', backgroundColor: '#85c4ff' }}>
                    <Typography fontSize={25}>{player.name}</Typography>
                </div>
                <Divider style={{ width: '100%' }} />
                <Typography fontSize={25}>{player.calculateMatchAverage(startingPoints)}</Typography>
                <Divider style={{ width: '100%' }} />
                <Typography fontSize={25}>{player.getScoredAmount180()}</Typography>
                <Typography fontSize={25}>{player.getScoredAmountBetween140And179()}</Typography>
                <Typography fontSize={25}>{player.getScoredAmountBetween100And139()}</Typography>
                <Typography fontSize={25}>{player.getScoredAmountBetween80And99()}</Typography>
                <Divider style={{ width: '100%' }} />
                <Typography fontSize={25}>{player.getAllHighFinishes()}</Typography>
            </Stack>
        </Box>
    )
}

export default StandardGameCurrentPlayerStatsComponent