import { FormControl, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box } from '@mui/system';
import React, { useState } from "react";
import { useRecoilState } from 'recoil';
import Player from '../models/Player';
import { GameName } from "../types/GameName";
import { playersState } from "./atoms";
import AroundTheClockGameSettingsComponent from './game/aroundtheclock/AroundTheClockGameSettingsComponent';
import StandardGameSettingsComponent from './game/standard/StandardGameSettingsComponent';
import './gameSettings.css';


const GameSettingsComponent = () => {

  const [players, setPlayers] = useRecoilState(playersState)
  const [gameName, setGameName] = useState(GameName.Standard);
  const [startStandardGame, setStartStandardGame] = useState<number>(0);
  const [startAroundTheClockGame, setStartAroundTheClockGame] = useState<number>(0);

  const handleGameNameSelect = (event: SelectChangeEvent) => {
    setGameName(event.target.value as GameName);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (gameName === GameName.Standard) {
      setStartStandardGame(startStandardGame => startStandardGame + 1);
    } else if (gameName === GameName.AroundTheClock) {
      setStartAroundTheClockGame(startAroundTheClockGame => startAroundTheClockGame + 1);
    }
  }

  const initPlayers = () => {
    let editablePlayers = players.map((p) => {
      return new Player(p.id, p.name, p.initialStartingOrder, []);
    });

    editablePlayers.sort((a, b) => (a.initialStartingOrder < b.initialStartingOrder ? -1 : 1));
    setPlayers(editablePlayers);
  }

  const font_size = 28

  const key_style = {
    display: "flex",
    width: "160px",
    height: "56px",
    alignItems: "center",
    justifyContent: "right"
  }

  const value_style = {
    display: "flex",
    width: "350px",
    height: "56px",
    alignItems: "center",
    justifyContent: "center"
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="main-container">
          <div className="container">
            <div className="item">
              <Box sx={key_style}>
                <InputLabel sx={{ fontSize: font_size }}>GAME</InputLabel>
              </Box>
            </div>
            <div className="value">
              <Box sx={value_style}>
                <FormControl variant="standard">
                  <Select
                    id="game-name-select"
                    value={gameName.toString()}
                    onChange={handleGameNameSelect}
                    sx={{ minWidth: 180, fontSize: font_size }}
                  >
                    <MenuItem sx={{ fontSize: font_size }} value={GameName.Standard}>{GameName.Standard}</MenuItem>
                    <MenuItem sx={{ fontSize: font_size }} value={GameName.AroundTheClock}>{GameName.AroundTheClock}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>

          {
            gameName === GameName.Standard
            && <StandardGameSettingsComponent players={players} initPlayers={initPlayers} startGame={startStandardGame} />
          }
          {
            gameName === GameName.AroundTheClock
            && <AroundTheClockGameSettingsComponent players={players} initPlayers={initPlayers} startGame={startAroundTheClockGame} />
          }

          <div className="container">
            <div className="center-text">
              <Button disabled={players.length === 0} style={{ fontSize: font_size }} variant="contained" type="submit">GAME ON</Button>
            </div>
          </div>
        </div>
      </form >
    </div >
  )
}

export default GameSettingsComponent