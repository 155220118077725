import { atom } from 'recoil';
import { AroundTheClockGame } from '../models/aroundtheclock/AroundTheClockGame';
import { StandardGame } from '../models/StandardGame';


export const playersState = atom({
  key: 'playersState', // unique ID (with respect to other atoms/selectors)
  default: [] // default value (aka initial value)
});

export const standardGameState = atom<StandardGame>({
  key: 'standardGameState' // unique ID (with respect to other atoms/selectors)
});

export const aroundTheClockGameState = atom<AroundTheClockGame>({
  key: 'aroundTheClockGameState' // unique ID (with respect to other atoms/selectors)
});

export const loginState = atom({
  key: 'loginState', // unique ID (with respect to other atoms/selectors)
  default: false // default value (aka initial value)
});