import { GameSettings } from "../GameSettings";

export class AroundTheClockGame extends GameSettings {

    // Defines the number where to start
    startingNumber: number;
    // Defines the number where to finish
    finishingNumber: number;

    constructor(rankingMode: boolean, startingNumber: number, finishingNumber: number) {
        super(rankingMode);
        this.startingNumber = startingNumber;
        this.finishingNumber = finishingNumber;
    }
}