export enum StartingPoints {
    POINT_301 = 301,
    POINT_501 = 501
}

export const StartingPointsValues: string[] = Object.keys(StartingPoints).filter((v) => !isNaN(Number(v)));

export const StartingPointsKey = (value: number): string => {
    const index = Object.values(StartingPoints).indexOf(value as unknown as StartingPoints);
    return Object.keys(StartingPoints)[index];
}

export const StartingPointsElement = (value: number): StartingPoints => {
    switch (value){
        case StartingPoints.POINT_301:
            return StartingPoints.POINT_301
        case StartingPoints.POINT_501:
            return StartingPoints.POINT_501
        default:
            throw new Error("<value> does not exist.")
    }
}