import { Box, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Switch, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import Player from '../../../models/Player'
import { StandardGame } from '../../../models/StandardGame'
import { CheckoutMode } from '../../../types/CheckoutMode'
import { GameMode } from '../../../types/GameMode'
import { StartingPoints } from '../../../types/StartingPoints'
import { standardGameState } from '../../atoms'


type StandardGameSettingsProp = {
    players: Player[];
    initPlayers: () => void;
    startGame: number;
}

const StandardGameSettingsComponent = ({ players, initPlayers, startGame }: StandardGameSettingsProp) => {

    const navigate = useNavigate();

    // const [gameName, setGameName] = useState(GameName.Standard);
    const setStandardGameState = useSetRecoilState(standardGameState)
    const [gameMode, setGameMode] = useState(GameMode.Legs);
    const [startingPoints, setStartingPoints] = useState(StartingPoints.POINT_501);
    const [checkoutMode, setCheckoutMode] = useState(CheckoutMode.Double);
    const [isRankingMode, setRankingMode] = React.useState(true);
    const [legs, setLegs] = React.useState(3);
    const [sets, setSets] = React.useState(3);

    useEffect(() => {
        if (startGame) {
            startStandardGame();
        }
      }, [startGame]);
      
    const startStandardGame = () => {
        const game = new StandardGame(players.length > 2 ? isRankingMode : false, startingPoints, checkoutMode, gameMode, legs, gameMode === GameMode.Sets ? sets : 0);
        setStandardGameState(game);

        initPlayers();
        navigate('/game/standard')
    }

    const handleStartingPointsSelect = (event: SelectChangeEvent) => {
        setStartingPoints(Number(event.target.value) as StartingPoints)
    };

    const handleCheckoutSelect = (event: SelectChangeEvent) => {
        setCheckoutMode(event.target.value as CheckoutMode);
    };

    const handleGameModeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGameMode((event.target as HTMLInputElement).value as GameMode);
    };

    const handleLegsSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLegs(Number(e.currentTarget.value))
    }

    const handleSetsSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSets(Number(e.currentTarget.value))
    }

    const isRankingModeDisabled = (): boolean => {
        return players.length <= 2
    }

    const handleRankingModeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRankingMode(e.target.checked);
    }

    const font_size = 28

    const key_style = {
        display: "flex",
        width: "160px",
        height: "56px",
        alignItems: "center",
        justifyContent: "right"
    }

    const value_style = {
        display: "flex",
        width: "350px",
        height: "56px",
        alignItems: "center",
        justifyContent: "center"
    }

    return (
        <div>
            <Divider />
            <div className="container">
                <div className="item">
                    <Box sx={key_style}>
                        <InputLabel sx={{ fontSize: font_size }}>POINTS</InputLabel>
                    </Box>
                </div>
                <div className="value">
                    <Box sx={value_style}>
                        <FormControl variant="standard">
                            <Select
                                id="starting-points-select"
                                value={startingPoints.toString()}
                                onChange={handleStartingPointsSelect}
                                sx={{ minWidth: 180, fontSize: font_size }}
                            >
                                <MenuItem sx={{ fontSize: font_size }} value={StartingPoints.POINT_301}>{StartingPoints.POINT_301}</MenuItem>
                                <MenuItem sx={{ fontSize: font_size }} value={StartingPoints.POINT_501}>{StartingPoints.POINT_501}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </div>
            </div>

            <Divider />

            <div className="container">
                <div className="item">
                    <Box sx={key_style}>
                        <InputLabel sx={{ fontSize: font_size }}>CHECKOUT</InputLabel>
                    </Box>
                </div>
                <div className="value">
                    <Box sx={value_style}>
                        <FormControl variant="standard">
                            <Select
                                id="checkout-points-select"
                                value={checkoutMode}
                                onChange={handleCheckoutSelect}
                                sx={{ minWidth: 180, fontSize: font_size }}
                            >
                                {/* <MenuItem sx={{ fontSize: font_size }} value={Checkout.Single}>{Checkout.Single}</MenuItem> */}
                                <MenuItem sx={{ fontSize: font_size }} value={CheckoutMode.Double}>{CheckoutMode.Double}</MenuItem>
                                {/* <MenuItem sx={{ fontSize: font_size }} value={Checkout.Tripple}>{Checkout.Tripple}</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Box>
                </div>
            </div>

            <Divider />

            <div className="container">
                <div className="item">
                    <Box sx={key_style}>
                        <InputLabel sx={{ fontSize: font_size }}>MODE</InputLabel>
                    </Box>
                </div>

                <div className="value radio">
                    <Box sx={value_style}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={handleGameModeSelect}
                            value={gameMode}
                        >
                            <FormControlLabel value={GameMode.Legs} control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: font_size } }} />} label={<Typography style={{ fontSize: font_size }}>Legs</Typography>} />
                            <FormControlLabel value={GameMode.Sets} control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: font_size } }} />} label={<Typography style={{ fontSize: font_size }}>Sets</Typography>} />
                        </RadioGroup>
                    </Box>
                </div>
            </div>

            <Divider />

            <div className="container">
                <div className="item">
                    <Box sx={key_style}>
                        <InputLabel sx={{ fontSize: font_size }}>LEGS</InputLabel>
                    </Box>
                </div>
                <div className="value">
                    <Box sx={value_style}>
                        <TextField
                            id="legs-to-win-input"
                            inputProps={{ min: 1, max: 20, style: { textAlign: 'center', fontSize: font_size } }}
                            variant="standard"
                            type="number"
                            value={legs}
                            onChange={handleLegsSelect}
                            sx={{ minWidth: 130 }}
                            required
                        />
                    </Box>
                </div>
            </div>

            <Divider />

            <div className="container">
                <div className="item">
                    <Box sx={key_style}>
                        <InputLabel sx={{ fontSize: font_size }}>SETS</InputLabel>
                    </Box>
                </div>
                <div className="value">
                    <Box sx={value_style}>
                        <TextField
                            id="sets-to-win-input"
                            inputProps={{ min: 1, max: 20, style: { textAlign: 'center', fontSize: font_size } }}
                            variant="standard"
                            type="number"
                            value={sets}
                            onChange={handleSetsSelect}
                            sx={{ minWidth: 130 }}
                            required
                        />
                    </Box>
                </div>
            </div>

            <Divider />

            <div className="container">
                <div className="item">
                    <Box sx={key_style}>
                        <InputLabel sx={{ fontSize: font_size }}>RANKING</InputLabel>
                    </Box>
                </div>
                <div className="value">
                    <Box sx={value_style}>
                        <Tooltip title={<Typography fontSize={25}>PLAY UNTIL ALL PLACES ARE TAKEN <br /> (MIN. 3 PLAYERS)</Typography>}>
                            <span>
                                <Switch disabled={isRankingModeDisabled()} checked={isRankingMode} onChange={handleRankingModeSelect} />
                            </span>
                        </Tooltip>
                    </Box>
                </div>
            </div>

        </div>
    )
}

export default StandardGameSettingsComponent