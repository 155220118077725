interface ILeg {
    id?: string | null;
    currentScore: number;
    started: boolean;
    // scoredAmountBetween80And99?: number;
    // scoredAmountBetween100And139?: number;
    // scoredAmountBetween140And179?: number;
    // scoredAmount180?: number;
    checkout?: number;
}

class Leg {

    id?: string | null;
    currentScore: number;
    started: boolean;
    scoredAmountBetween80And99: number = 0;
    scoredAmountBetween100And139: number = 0;
    scoredAmountBetween140And179: number = 0;
    scoredAmount180: number = 0;
    checkout?: number;
    thrownDarts: number = 0;
    won: boolean;

    constructor();
    constructor(obj: ILeg);
    constructor(obj?: ILeg) {
        this.id = !obj ? null : obj.id;
        this.currentScore = !obj ? 501 : obj.currentScore;
        this.started = !obj ? false : obj.started;
        this.scoredAmountBetween80And99 = 0;
        this.scoredAmountBetween100And139 = 0;
        this.scoredAmountBetween140And179 = 0;
        this.scoredAmount180 = 0;
        // this.scoredAmountBetween80And99 = !obj ? 0 : obj.scoredAmountBetween80And99;
        // this.scoredAmountBetween100And139 = !obj ? 0 : obj.scoredAmountBetween100And139;
        // this.scoredAmountBetween140And179 = !obj ? 0 : obj.scoredAmountBetween140And179;
        // this.scoredAmount180 = !obj ? 0 : obj.scoredAmount180;
        this.checkout = !obj ? 0 : obj.checkout;
        this.thrownDarts = 0;
        this.won = false;
    }

    trackIfHighScore(score: number) {
        if (score === 180) {
            this.scoredAmount180++;
        } else if (score >= 140) {
            this.scoredAmountBetween140And179++;
        } else if (score >= 100) {
            this.scoredAmountBetween100And139++;
        } else if (score >= 80) {
            this.scoredAmountBetween80And99++;
        }
    }

    undoIfHighScore(score: number) {
        if (score === 180) {
            this.scoredAmount180--;
        } else if (score >= 140) {
            this.scoredAmountBetween140And179--;
        } else if (score >= 100) {
            this.scoredAmountBetween100And139--;
        } else if (score >= 80) {
            this.scoredAmountBetween80And99--;
        }
    }

    setThrownDarts(amount: number) {
        if (this.thrownDarts == null || this.thrownDarts === 0) {
            this.thrownDarts = amount;
        } else {
            this.thrownDarts += amount;
        }
    }

    calculateThreeDartLegAverage(startingPoints: number) {
        if (this.thrownDarts == null || this.thrownDarts === 0) {
            return "0";
        }

        let scored = startingPoints - this.currentScore
        return ((scored / this.thrownDarts) * 3).toFixed(2);
    }
}

export default Leg