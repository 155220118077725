import { InputLabel, Stack } from '@mui/material';
import React from 'react';
import './standardGamePlayer.css';

type LegsProps = {
  wonLegs: number
}

const StandardGameLegsComponent = ({ wonLegs }: LegsProps) => {
  return (
    <div className="player-container">
      <div className="one-item">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          margin={-0.5}
        >
          <InputLabel sx={{ fontSize: 30 }}>LEGS</InputLabel>
          <InputLabel sx={{ fontSize: 35, color: 'black' }}>{wonLegs}</InputLabel>
        </Stack>
      </div>
    </div>
  )
}

export default StandardGameLegsComponent