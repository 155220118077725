import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React, { useRef, useState } from 'react';
import PlayerMasterData from '../../models/PlayerMasterData';


type EnterPlayerMaterDataProps = {
  players: PlayerMasterData[],
  setPlayers: React.Dispatch<React.SetStateAction<PlayerMasterData[]>>
}

const EnterPlayerMasterDataComponent = ({ players, setPlayers }: EnterPlayerMaterDataProps) => {
  const [error, setError] = useState('');

  const inputRef = useRef(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value
    if (name != null || name !== "") {
      if (name.trim().length < 3) {
        setError("Min 3 characters")
      } else if (name.length > 10) {
        setError("Max 10 characters")
      } else if (isNameAlreadyPresent(players, name)) {
        setError("Name already used.")
      } else if (name.includes(' ')) {
        setError("Whitespace not allowed.")
      }
      else {
        setError("")
      }
    }
  }

  const onEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      addPlayer()
    }
  }

  const addPlayer = () => {
    const input = document.getElementById('player-name-input') as HTMLInputElement | null;

    if (error === "" && input != null && input.value !== "") {
      setPlayers(players.concat(new PlayerMasterData(input.value)));
      input.value = ""
    }
  }

  function isNameAlreadyPresent(players: PlayerMasterData[], name: string): boolean {
    return players.find(p => p.name === name) != null;
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <div>
        <TextField
          sx={{
            'MuiInputLabel': {
              defaultProps: {
                sx: {
                  fontSize: "30px",
                },
              },
            }
          }}
          InputProps={{
            type: 'search',
            style: { fontSize: 30, width: 330 }
          }}
          ref={inputRef}
          error={error !== ""}
          helperText={error}
          id="player-name-input"
          label="NAME"
          onChange={onChange}
          onKeyUp={onEnter}
          FormHelperTextProps={{
            style: { fontSize: 30 }
          }}
        />
        <IconButton
          onClick={(e) => addPlayer()}
        >
          <AddIcon sx={{ fontSize: "60px", color: "#1976d2" }} />
        </IconButton>
      </div>
    </Stack>
  )
}

export default EnterPlayerMasterDataComponent