import { AxiosResponse } from "axios";
import http from "../http-common";
import StandardGameOverallPlayerStats from "../models/StandardGameOverallPlayerStats";
import StandardGameOverview from "../models/StandardGameOverview";
import AuthService from "./AuthService";

class StandardGameService {

    save(stats: StandardGameOverview): Promise<AxiosResponse<StandardGameOverview, any>> {
        return http.post<StandardGameOverview>("/game/standard/stats/save", stats, AuthService.getAuthConfig());
    }

    findStats(playerId: string): Promise<AxiosResponse<StandardGameOverallPlayerStats, any>> {
        const url = `/game/standard/${playerId}/stats`
        return http.get<StandardGameOverallPlayerStats>(url, AuthService.getAuthConfig());
    }

}

export default new StandardGameService();