import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { AroundTheClockGame } from '../../../models/aroundtheclock/AroundTheClockGame';
import Player from '../../../models/Player';
import { aroundTheClockGameState } from '../../atoms';


type AroundTheClockGameSettingsProp = {
    players: Player[];
    initPlayers: () => void;
    startGame: number;
}

const AroundTheClockGameSettingsComponent = ({ players, initPlayers, startGame }: AroundTheClockGameSettingsProp) => {

    const navigate = useNavigate();

    const setAroundTheClockGameState = useSetRecoilState(aroundTheClockGameState)

    useEffect(() => {
        if (startGame) {
            startStandardGame();
        }
    }, [startGame]);


    const startStandardGame = () => {
        setAroundTheClockGameState(new AroundTheClockGame(false, 1, 50));
        initPlayers();
        navigate('/game/atc')
    }

    return (
        <div>
            
        </div>
    )
}

export default AroundTheClockGameSettingsComponent