import { InputLabel, Stack } from '@mui/material';
import React from 'react';
import './standardGamePlayer.css';


type SetsProps = {
    wonSets: number,
    wonLegs: number
}

const StandardGameSetsComponent = ({ wonSets, wonLegs }: SetsProps) => {
    return (
        <div className="player-container">
            <div className="first-out-of-two">
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    margin={-0.5}
                >
                    <InputLabel sx={{ fontSize: 30 }}>SETS</InputLabel>
                    <InputLabel sx={{ fontSize: 35, color: 'black' }}>{wonSets}</InputLabel>
                </Stack>
            </div>
            <div className="second-out-of-two">
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    margin={-0.5}
                >
                    <InputLabel sx={{ fontSize: 30 }}>LEGS</InputLabel>
                    <InputLabel sx={{ fontSize: 35, color: 'black' }}>{wonLegs}</InputLabel>
                </Stack>
            </div>
        </div>
    )
}

export default StandardGameSetsComponent