import { Box, InputLabel } from '@mui/material'
import React from 'react'

type DoubleOutOptionProp = {
    option: string
}

const StandardGameDoubleOutOptionComponent = ({ option }: DoubleOutOptionProp) => {
    return (
        <div className="player-container">
            <Box>
                <InputLabel sx={{ fontSize: 30, color: 'black' }}>
                    {option}
                </InputLabel>
            </Box>
        </div>
    )
}

export default StandardGameDoubleOutOptionComponent