import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Alert, Avatar, Box, Button, Container, createTheme, CssBaseline, Grid, Link, TextField, ThemeProvider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorResponseHandler from '../../helper/ErrorResponseHandler';
import AuthenticationRequest from '../../models/AuthenticationRequest';
import AuthService from '../../services/AuthService';
import CopyrightComponent from '../footer/CopyrightComponent';
import { loginState } from '../atoms';
import { useSetRecoilState } from 'recoil';

const theme = createTheme();

const LoginComponent = () => {

    const [errorMessage, setErrorMessage] = useState<string>(null);
    const setLoginStatus = useSetRecoilState(loginState);

    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const registration: AuthenticationRequest = {
            email: data.get('email'),
            password: data.get('password')
        };

        logIn(registration);
    };

    const logIn = async (registration: AuthenticationRequest) => {
        await AuthService.logIn(registration)
            .then(() => {
                setLoginStatus(true);
                navigate("/");
                setErrorMessage(null);
            }).catch((error) => {
                setErrorMessage(ErrorResponseHandler.getErrorMessage(error, "Invalid email or password"));
            });
    };


    return (
        <div>
            <Container maxWidth='xs'>
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                LOG IN
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <div>
                                    {
                                        errorMessage != null &&
                                        <Alert severity="error">{errorMessage}</Alert>
                                    }
                                </div>
                                {/* <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Remember me"
                                /> */}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Log in
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        {/* <Link href="#" variant="body2">
                                            Forgot password?
                                        </Link> */}
                                    </Grid>
                                    <Grid item>
                                        <Link href="/signup" variant="body2">
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <CopyrightComponent sx={{ mt: 8, mb: 4 }} />
                    </Container>
                </ThemeProvider>
            </Container>
        </div>
    );
}

export default LoginComponent