import { Box, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import StandardGameOverallPlayerStats from '../../../models/StandardGameOverallPlayerStats'

type StatsProps = {
    stats: StandardGameOverallPlayerStats
    isModalOpen: boolean
    closeModal: () => void
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24
};

const font_size = {
    fontSize: 30
}

const StandardGameOverallPlayerStatsComponent = ({ stats, isModalOpen, closeModal }: StatsProps) => {

    const toNumber = (value: number): number => {
        return value == null ? 0 : value;
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={style}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Typography id="modal-modal-title" variant="h3">
                    {stats != null &&
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={font_size} align="center">DARTS THROWN</TableCell>
                                        <TableCell sx={font_size} align="center">100+</TableCell>
                                        <TableCell sx={font_size} align="center">140+</TableCell>
                                        <TableCell sx={font_size} align="center">180</TableCell>
                                        <TableCell sx={font_size} align="center">HIGHEST FINISH</TableCell>
                                        <TableCell sx={font_size} align="center">BEST LEG (501)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        key={stats.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={font_size} align="center">{toNumber(stats.thrownDarts)}</TableCell>
                                        <TableCell sx={font_size} align="center">{toNumber(stats.scoredAmountOver100)}</TableCell>
                                        <TableCell sx={font_size} align="center">{toNumber(stats.scoredAmountOver140)}</TableCell>
                                        <TableCell sx={font_size} align="center">{toNumber(stats.scoredAmount180)}</TableCell>
                                        <TableCell sx={font_size} align="center">{toNumber(stats.highestFinish)}</TableCell>
                                        <TableCell sx={font_size} align="center">{stats.leastThrownDarts501 != null ? toNumber(stats.leastThrownDarts501) + " Darts" : "-"}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Typography>

            </Box>
        </Modal>
    )
}

export default StandardGameOverallPlayerStatsComponent