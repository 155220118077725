import { FieldType } from "../../types/FieldType";

class Dart {
    score: number;
    number: number;
    fieldType: FieldType;
    levelUp: boolean;

    constructor(number: number, fieldType: FieldType){
        this.number = number;
        this.fieldType = fieldType;
        this.levelUp = false;
    }
}

export default Dart;