class PlayerMasterData {

    id?: string | null;
    name: string;
    creationTimestamp: number;

    constructor(name: string) {
        this.id = null;
        this.name = name;
        this.creationTimestamp = Math.floor(Date.now() / 1000);
    }
}

export default PlayerMasterData;