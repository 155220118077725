import { Stack } from '@mui/material';
import React from 'react';

const HomeButtonComponent = () => {

    const logo: string = require("../../images/flight.svg").default;

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
        >
            <img width={40} src={logo} alt="SVG logo image" />
        </Stack>
    )
}

export default HomeButtonComponent