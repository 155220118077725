import { AxiosRequestConfig, AxiosResponse } from "axios";
import http from "../http-common";
import AuthenticationRequest from "../models/AuthenticationRequest";
import AuthenticationResponse from "../models/AuthenticationResponse";
import { Token } from "../models/Token";
import jwt_decode from 'jwt-decode';


const defaultHeaders = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Content-Length"
}

class AuthService {

    async signUp(registration: AuthenticationRequest): Promise<AxiosResponse<AuthenticationResponse, any>> {
        return http.post<AuthenticationRequest, AxiosResponse<AuthenticationResponse>>("/auth/signup", registration, {
            withCredentials: true,
            headers: defaultHeaders
        });
    }

    async logIn(registration: AuthenticationRequest): Promise<void> {
        await http.post<AuthenticationRequest, AxiosResponse<AuthenticationResponse>>("/auth/login", registration, {
            withCredentials: true,
            headers: defaultHeaders
        })
            .then((response) => {
                const authResponse = response.data;
                this.updateTokensInStorage(authResponse)
            });
    }

    async refreshAndSetTokens() {
        const response = await this.refreshTokens()
        const tokens = response.data;

        if (tokens.accessToken != null && tokens.refreshToken) {
            localStorage.setItem("access_token", JSON.stringify(tokens.accessToken));
            localStorage.setItem("refresh_token", JSON.stringify(tokens.refreshToken));
        }
    }

    private refreshTokens(): Promise<AxiosResponse<AuthenticationResponse, any>> {
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Content-Length",
            "Authorization": `Bearer ${this.getRefreshToken()}`
        }

        return http.get<AuthenticationRequest, AxiosResponse<AuthenticationResponse>>("/auth/refresh", {
            withCredentials: true,
            headers: headers
        });
    }

    updateTokensInStorage(response: AuthenticationResponse) {
        if (response.accessToken != null && response.refreshToken) {
            localStorage.setItem("access_token", JSON.stringify(response.accessToken));
            localStorage.setItem("refresh_token", JSON.stringify(response.refreshToken));
        }
    }

    isAccessTokenAvailable(): boolean {
        return this.getAccessToken() != null;
    }

    getAuthConfig(): AxiosRequestConfig<any> {
        return {
            headers: { Authorization: this.createBearerToken() }
        };
    }

    getEmailAddress = (): string => {
        if (this.isAccessTokenAvailable()) {
            const token: Token = jwt_decode(localStorage.getItem('access_token'));
            return token.sub;
        }
        
        return null;
    }

    createBearerToken(): string {
        return `Bearer ${this.getAccessToken()}`;
    }

    private getAccessToken(): string {
        const userStorage = localStorage.getItem('access_token');
        if (userStorage == null) {
            return null;
        } else {
            return JSON.parse(localStorage.getItem('access_token'));
        }
    }

    private getRefreshToken(): string {
        const userStorage = localStorage.getItem('refresh_token');
        if (userStorage == null) {
            return null;
        } else {
            return JSON.parse(localStorage.getItem('refresh_token'));
        }
    }

    logout() {
        localStorage.clear();
    }
}

export default new AuthService();