import Player from "./Player";
import { StandardGame } from "./StandardGame";

class StandardGameOverview {

    settings: StandardGame;
    players: Player[];
    winner: string;

    constructor(settings: StandardGame, players: Player[], winner: string) {
        this.settings = settings;
        this.players = players;
        this.winner = winner;
    }
}

export default StandardGameOverview;