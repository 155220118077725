class NineDarterOption {
    static readonly O_1 = new NineDarterOption(180, 180, 141);
    static readonly O_2 = new NineDarterOption(177, 170, 154);
    static readonly O_3 = new NineDarterOption(171, 170, 160);
    static readonly O_4 = new NineDarterOption(165, 180, 156);
    static readonly O_5 = new NineDarterOption(180, 177, 144);
    static readonly O_6 = new NineDarterOption(177, 168, 156);
    static readonly O_7 = new NineDarterOption(171, 160, 170);
    static readonly O_8 = new NineDarterOption(164, 180, 157);
    static readonly O_9 = new NineDarterOption(180, 174, 147);
    static readonly O_10 = new NineDarterOption(177, 167, 157);
    static readonly O_11 = new NineDarterOption(170, 180, 151);
    static readonly O_12 = new NineDarterOption(164, 177, 160);
    static readonly O_13 = new NineDarterOption(180, 171, 150);
    static readonly O_14 = new NineDarterOption(177, 164, 160);
    static readonly O_15 = new NineDarterOption(170, 177, 154);
    static readonly O_16 = new NineDarterOption(164, 170, 167);
    static readonly O_17 = new NineDarterOption(180, 170, 151);
    static readonly O_18 = new NineDarterOption(177, 160, 164);
    static readonly O_19 = new NineDarterOption(170, 174, 157);
    static readonly O_20 = new NineDarterOption(164, 167, 170);
    static readonly O_21 = new NineDarterOption(180, 168, 153);
    static readonly O_22 = new NineDarterOption(177, 157, 167);
    static readonly O_23 = new NineDarterOption(170, 171, 160);
    static readonly O_24 = new NineDarterOption(161, 180, 160);
    static readonly O_25 = new NineDarterOption(180, 167, 154);
    static readonly O_26 = new NineDarterOption(177, 154, 170);
    static readonly O_27 = new NineDarterOption(170, 170, 161);
    static readonly O_28 = new NineDarterOption(161, 170, 170);
    static readonly O_29 = new NineDarterOption(180, 165, 156);
    static readonly O_30 = new NineDarterOption(174, 180, 147);
    static readonly O_31 = new NineDarterOption(170, 167, 164);
    static readonly O_32 = new NineDarterOption(160, 180, 161);
    static readonly O_33 = new NineDarterOption(180, 164, 157);
    static readonly O_34 = new NineDarterOption(174, 177, 150);
    static readonly O_35 = new NineDarterOption(170, 164, 167);
    static readonly O_36 = new NineDarterOption(160, 177, 164);
    static readonly O_37 = new NineDarterOption(180, 161, 160);
    static readonly O_38 = new NineDarterOption(174, 174, 153);
    static readonly O_39 = new NineDarterOption(170, 161, 170);
    static readonly O_40 = new NineDarterOption(160, 174, 167);
    static readonly O_41 = new NineDarterOption(180, 160, 161);
    static readonly O_42 = new NineDarterOption(174, 171, 156);
    static readonly O_43 = new NineDarterOption(168, 180, 153);
    static readonly O_44 = new NineDarterOption(160, 171, 170);
    static readonly O_45 = new NineDarterOption(180, 157, 164);
    static readonly O_46 = new NineDarterOption(174, 170, 157);
    static readonly O_47 = new NineDarterOption(168, 177, 156);
    static readonly O_48 = new NineDarterOption(157, 180, 164);
    static readonly O_49 = new NineDarterOption(180, 154, 167);
    static readonly O_50 = new NineDarterOption(174, 167, 160);
    static readonly O_51 = new NineDarterOption(167, 180, 154);
    static readonly O_52 = new NineDarterOption(157, 177, 167);
    static readonly O_53 = new NineDarterOption(180, 151, 170);
    static readonly O_54 = new NineDarterOption(174, 160, 167);
    static readonly O_55 = new NineDarterOption(167, 177, 157);
    static readonly O_56 = new NineDarterOption(157, 174, 170);
    static readonly O_57 = new NineDarterOption(177, 180, 144);
    static readonly O_58 = new NineDarterOption(174, 157, 170);
    static readonly O_59 = new NineDarterOption(167, 174, 160);
    static readonly O_60 = new NineDarterOption(154, 180, 167);
    static readonly O_61 = new NineDarterOption(177, 177, 147);
    static readonly O_62 = new NineDarterOption(171, 180, 150);
    static readonly O_63 = new NineDarterOption(167, 170, 164);
    static readonly O_64 = new NineDarterOption(154, 177, 170);
    static readonly O_65 = new NineDarterOption(177, 174, 150);
    static readonly O_66 = new NineDarterOption(171, 177, 153);
    static readonly O_67 = new NineDarterOption(167, 167, 167);
    static readonly O_68 = new NineDarterOption(151, 180, 170);
    static readonly O_69 = new NineDarterOption(177, 171, 153);
    static readonly O_70 = new NineDarterOption(171, 174, 156);
    static readonly O_71 = new NineDarterOption(167, 164, 170);

    private constructor(
        public readonly round1: number,
        public readonly round2: number,
        public readonly round3: number) {
    }
}

export const NineDarterOptions = [
    NineDarterOption.O_1,
    NineDarterOption.O_2,
    NineDarterOption.O_3,
    NineDarterOption.O_4,
    NineDarterOption.O_5,
    NineDarterOption.O_6,
    NineDarterOption.O_7,
    NineDarterOption.O_8,
    NineDarterOption.O_9,
    NineDarterOption.O_10,
    NineDarterOption.O_11,
    NineDarterOption.O_12,
    NineDarterOption.O_13,
    NineDarterOption.O_14,
    NineDarterOption.O_15,
    NineDarterOption.O_16,
    NineDarterOption.O_17,
    NineDarterOption.O_18,
    NineDarterOption.O_19,
    NineDarterOption.O_20,
    NineDarterOption.O_21,
    NineDarterOption.O_22,
    NineDarterOption.O_23,
    NineDarterOption.O_24,
    NineDarterOption.O_25,
    NineDarterOption.O_26,
    NineDarterOption.O_27,
    NineDarterOption.O_28,
    NineDarterOption.O_29,
    NineDarterOption.O_30,
    NineDarterOption.O_31,
    NineDarterOption.O_32,
    NineDarterOption.O_33,
    NineDarterOption.O_34,
    NineDarterOption.O_35,
    NineDarterOption.O_36,
    NineDarterOption.O_37,
    NineDarterOption.O_38,
    NineDarterOption.O_39,
    NineDarterOption.O_40,
    NineDarterOption.O_41,
    NineDarterOption.O_42,
    NineDarterOption.O_43,
    NineDarterOption.O_44,
    NineDarterOption.O_45,
    NineDarterOption.O_46,
    NineDarterOption.O_47,
    NineDarterOption.O_48,
    NineDarterOption.O_49,
    NineDarterOption.O_50,
    NineDarterOption.O_51,
    NineDarterOption.O_52,
    NineDarterOption.O_53,
    NineDarterOption.O_54,
    NineDarterOption.O_55,
    NineDarterOption.O_56,
    NineDarterOption.O_57,
    NineDarterOption.O_58,
    NineDarterOption.O_59,
    NineDarterOption.O_60,
    NineDarterOption.O_61,
    NineDarterOption.O_62,
    NineDarterOption.O_63,
    NineDarterOption.O_64,
    NineDarterOption.O_65,
    NineDarterOption.O_66,
    NineDarterOption.O_67,
    NineDarterOption.O_68,
    NineDarterOption.O_69,
    NineDarterOption.O_70,
    NineDarterOption.O_71
]