import React from 'react';
import { FieldType } from '../../types/FieldType';

type DartboardProps = {
    scored: (number: number, fieldType: FieldType) => void;
}

const DartboardComponent = ({ scored }: DartboardProps) => {

    const onSingle = (score: number): void => {
        console.log("SINGLE: " + score);
        scored(score, FieldType.Single);
    }

    const onDouble = (score: number): void => {
        console.log("DOUBLE: " + score);
        scored(score, FieldType.Double);
    }

    const onTriple = (score: number): void => {
        console.log("TRIPLE: " + score);
        scored(score, FieldType.Triple);
    }

    const onSurround = (): void => {
        console.log("onSurround");
        scored(0, FieldType.Surround);
    }

    return (
        <div style={{ height: 700, width: 700 }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                id="SVGRoot"
                width="300mm"
                height="300mm"
                version="1.1"
                viewBox="10 30 450 450"
                xmlSpace="preserve"
            >
                <defs>
                    <linearGradient id="linearGradient18511">
                        <stop offset="0" stopColor="gray"></stop>
                    </linearGradient>
                    <linearGradient
                        id="linearGradient18513"
                        x1="127.25"
                        x2="153.01"
                        y1="87.44"
                        y2="87.44"
                        gradientUnits="userSpaceOnUse"
                        xlinkHref="#linearGradient18511"
                    ></linearGradient>
                    <linearGradient
                        id="linearGradient18581"
                        x1="128.06"
                        x2="158.83"
                        y1="69.264"
                        y2="69.264"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stopColor="gray"></stop>
                    </linearGradient>
                </defs>
                <path
                    id="surround"
                    onClick={() => { onSurround() }}
                    stroke="#d40000"
                    strokeWidth="0"
                    d="M268.907 166.076a125 125 0 01-125 125 125 125 0 01-125-125 125 125 0 01125-125 125 125 0 01125 125z"
                ></path>
                <circle
                    cx="143.75"
                    cy="165.85"
                    r="100"
                    fill="gray"
                    strokeWidth="0.219"
                ></circle>
                <g stroke="gray">
                    <g strokeWidth="0.291">
                        <path
                            id="outerEleven"
                            onClick={() => { onSingle(11) }}
                            fill="#ffb380"
                            d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                            className="UnoptimicedTransforms"
                            transform="matrix(-.00239 -1.0799 .98256 -.00217 -18.965 317.52)"
                        ></path>
                        <path
                            id="outerFourteen"
                            onClick={() => { onSingle(14) }}
                            d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.33121 -1.0279 .93521 .30135 -57.858 259.87)"
                        ></path>
                        <path
                            id="outerNine"
                            onClick={() => { onSingle(9) }}
                            fill="#ffb380"
                            d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.63264 -.87519 .7963 .57561 -77.096 192.94)"
                        ></path>
                        <path
                            id="outerTwelve"
                            onClick={() => { onSingle(12) }}
                            d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.87345 -.63504 .57779 .79472 -74.571 122.98)"
                        ></path>
                    </g>
                    <path
                        id="outerSixteen"
                        onClick={() => { onSingle(16) }}
                        fill="#ffb380"
                        strokeWidth="0.291"
                        d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-.63468 -.87371 .79495 -.57747 100.62 384.56)"
                    ></path>
                    <path
                        id="outerEight"
                        onClick={() => { onSingle(8) }}
                        strokeWidth="0.291"
                        d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-.33117 -1.0279 .93522 -.30131 34.956 360.29)"
                    ></path>
                    <g strokeWidth="0.64">
                        <path
                            id="doubleTwenty"
                            onClick={() => { onDouble(20) }}
                            fill="#de0000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.96289 0 0 .91292 5.645 8.24)"
                        ></path>
                        <path
                            id="doubleOne"
                            onClick={() => { onDouble(1) }}
                            fill="#338000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.91586 .29723 -.28181 .86834 61.143 -26.63)"
                        ></path>
                        <path
                            id="doubleEighteen"
                            onClick={() => { onDouble(18) }}
                            fill="#de0000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.77999 .5646 -.5353 .73951 124.48 -42.676)"
                        ></path>
                    </g>
                    <g strokeWidth="0.64">
                        <path
                            id="doubleFour"
                            onClick={() => { onDouble(4) }}
                            fill="#338000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.5623 .78164 -.74108 .53312 190.99 -38.417)"
                        ></path>
                        <path
                            id="doubleThirteen"
                            onClick={() => { onDouble(13) }}
                            fill="#de0000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.29604 .91625 -.8687 .28068 251.19 -13.965)"
                        ></path>
                        <path
                            id="doubleSix"
                            onClick={() => { onDouble(6) }}
                            fill="#338000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.0024 .96288 -.91292 .00227 301.23 27.901)"
                        ></path>
                        <path
                            id="doubleTen"
                            onClick={() => { onDouble(10) }}
                            fill="#de0000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(-.29266 .91733 -.86973 -.27748 336.01 83.028)"
                        ></path>
                        <path
                            id="doubleFifteen"
                            onClick={() => { onDouble(15) }}
                            fill="#338000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(-.56591 .77904 -.73861 -.53654 352.65 147.14)"
                        ></path>
                        <path
                            id="doubleTwo"
                            onClick={() => { onDouble(2) }}
                            fill="#de0000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(-.7781 .5672 -.53777 -.73772 348.37 212.42)"
                        ></path>
                        <path
                            id="doubleSeventeen"
                            onClick={() => { onDouble(17) }}
                            fill="#338000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(-.91638 .29563 -.28029 -.86883 323.94 273.9)"
                        ></path>
                        <path
                            id="doubleThree"
                            onClick={() => { onDouble(3) }}
                            fill="#de0000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(-.96289 .00101 -.00096 -.91292 281.93 323.79)"
                        ></path>
                        <path
                            id="doubleNineteen"
                            onClick={() => { onDouble(19) }}
                            fill="#338000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(-.91601 -.29677 .28137 -.86848 226.43 358.71)"
                        ></path>
                        <path
                            id="doubleSeven"
                            onClick={() => { onDouble(7) }}
                            fill="#de0000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(-.77481 -.57168 .54202 -.7346 161.8 375.36)"
                        ></path>
                        <path
                            id="doubleSixteen"
                            onClick={() => { onDouble(16) }}
                            fill="#338000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(-.56145 -.78226 .74167 -.53231 96.494 370.66)"
                        ></path>
                        <path
                            id="doubleEight"
                            onClick={() => { onDouble(18) }}
                            fill="#de0000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(-.29415 -.91686 .86928 -.27888 36.033 346.11)"
                        ></path>
                        <path
                            id="doubleEleven"
                            onClick={() => { onDouble(11) }}
                            fill="#338000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.00457 -.96288 .91291 .00434 -14.383 303.8)"
                        ></path>
                        <path
                            id="doubleFourteen"
                            onClick={() => { onDouble(14) }}
                            fill="#de0000"
                            d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.29201 -.91754 .86993 .27685 -48.013 249.34)"
                        ></path>
                    </g>
                    <path
                        id="doubleNine"
                        onClick={() => { onDouble(9) }}
                        fill="#338000"
                        strokeWidth="0.64"
                        d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                        className="UnoptimicedTransforms"
                        transform="matrix(.55679 -.78558 .74481 .5279 -63.981 186.51)"
                    ></path>
                    <path
                        id="doubleTwelve"
                        onClick={() => { onDouble(12) }}
                        fill="#de0000"
                        strokeWidth="0.64"
                        d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                        className="UnoptimicedTransforms"
                        transform="matrix(.77962 -.5651 .53578 .73917 -60.765 119.25)"
                    ></path>
                    <path
                        id="doubleFive"
                        onClick={() => { onDouble(5) }}
                        fill="#338000"
                        strokeWidth="0.64"
                        d="M127.74 65.285c10.444-1.652 20.983-1.374 31.209-.049l-1.138 8.893c-9.637-1.216-19.324-1.508-28.653.008z"
                        className="UnoptimicedTransforms"
                        transform="matrix(.91818 -.28999 .27495 .87053 -36.033 57.486)"
                    ></path>
                    <path
                        id="tripleTwenty"
                        onClick={() => { onTriple(20) }}
                        fill="#de0000"
                        strokeWidth="0.365"
                        d="M128.05 65.309c10.179-.918 20.495-1.041 30.77-.007a424.89 424.89 0 00-2.25 8.643c-8.815-.818-17.751-.723-26.67.006-.6-2.883-1.216-5.764-1.85-8.642z"
                        className="UnoptimicedTransforms"
                        transform="matrix(.62376 0 0 1.0817 54.304 31.425)"
                    ></path>
                </g>
                <path
                    id="tripleOne"
                    onClick={() => { onTriple(1) }}
                    fill="#338000"
                    stroke="url(#linearGradient18581)"
                    strokeWidth="0.363"
                    d="M128.06 65.333c10.177-.927 20.492-1.06 30.768-.035a435.884 435.884 0 00-2.212 8.647c-8.566-.798-17.247-.734-25.915-.063a458.682 458.682 0 00-2.641-8.549z"
                    className="UnoptimicedTransforms"
                    transform="matrix(.59934 .19416 -.34046 1.028 99.788 10.331)"
                ></path>
                <g stroke="gray">
                    <g strokeWidth="0.366">
                        <path
                            id="tripleEighteen"
                            onClick={() => { onTriple(18) }}
                            fill="#de0000"
                            d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.50447 .36929 -.64259 .86001 150.85 5.299)"
                        ></path>
                        <path
                            id="tripleFour"
                            onClick={() => { onTriple(4) }}
                            fill="#338000"
                            d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.36489 .50766 -.87784 .61801 200.2 15.461)"
                        ></path>
                        <path
                            id="tripleThirteen"
                            onClick={() => { onTriple(13) }}
                            fill="#de0000"
                            d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.19158 .59512 -1.0251 .31895 243.66 40.03)"
                        ></path>
                    </g>
                    <path
                        id="tripleSix"
                        onClick={() => { onTriple(6) }}
                        fill="#338000"
                        strokeWidth="0.61"
                        d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                        className="UnoptimicedTransforms"
                        transform="matrix(.00355 .62518 -1.0736 -.00443 276.82 76.346)"
                    ></path>
                    <path
                        id="tripleTen"
                        onClick={() => { onTriple(10) }}
                        fill="#de0000"
                        strokeWidth="0.61"
                        d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-.19115 .59525 -1.0189 -.33824 298.11 122.23)"
                    ></path>
                    <path
                        id="tripleFifteen"
                        onClick={() => { onTriple(15) }}
                        fill="#338000"
                        strokeWidth="0.854"
                        d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-.36707 .50609 -.86282 -.63882 304.06 172.33)"
                    ></path>
                    <path
                        id="tripleTwo"
                        onClick={() => { onTriple(2) }}
                        fill="#de0000"
                        strokeWidth="0.366"
                        d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-.50468 .369 -.62512 -.87279 294.39 221.38)"
                    ></path>
                    <path
                        id="tripleSeventeen"
                        onClick={() => { onTriple(17) }}
                        fill="#338000"
                        strokeWidth="0.732"
                        d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-.59474 .19273 -.32093 -1.0245 269.6 265.77)"
                    ></path>
                    <path
                        id="tripleThree"
                        onClick={() => { onTriple(3) }}
                        fill="#de0000"
                        strokeWidth="0.366"
                        d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-.62517 .00576 .00064 -1.0736 233.39 298.94)"
                    ></path>
                    <path
                        id="tripleNineteen"
                        onClick={() => { onTriple(19) }}
                        fill="#338000"
                        strokeWidth="0.366"
                        d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-.59608 -.18856 .33381 -1.0204 187.97 320.33)"
                    ></path>
                    <path
                        id="tripleSeven"
                        onClick={() => { onTriple(7) }}
                        fill="#de0000"
                        strokeWidth="0.61"
                        d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-.50852 -.3637 .63307 -.86705 137.89 326.28)"
                    ></path>
                    <path
                        id="tripleSixteen"
                        onClick={() => { onTriple(16) }}
                        fill="#338000"
                        strokeWidth="0.366"
                        d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-.37111 -.50313 .87017 -.62877 88.504 316.82)"
                    ></path>
                    <path
                        id="tripleEight"
                        onClick={() => { onTriple(8) }}
                        fill="#de0000"
                        strokeWidth="0.366"
                        d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-.19523 -.59393 1.0231 -.32524 44.34 292.13)"
                    ></path>
                    <g strokeWidth="0.366">
                        <path
                            id="tripleEleven"
                            onClick={() => { onTriple(11) }}
                            fill="#4a0"
                            d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                            className="UnoptimicedTransforms"
                            transform="matrix(-.0031 -.62518 1.0736 .0052 10.328 255.44)"
                        ></path>
                        <path
                            id="tripleFourteen"
                            onClick={() => { onTriple(14) }}
                            fill="#de0000"
                            d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.18653 -.59672 1.0215 .33033 -10.507 210.54)"
                        ></path>
                        <path
                            id="tripleNine"
                            onClick={() => { onTriple(9) }}
                            fill="#338000"
                            d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.36501 -.50758 .86541 .6353 -16.921 159.94)"
                        ></path>
                        <path
                            id="tripleTwelve"
                            onClick={() => { onTriple(12) }}
                            fill="#de0000"
                            d="M128.06 65.325c10.178-.921 20.493-1.05 30.768-.019a576.154 576.154 0 00-1.875 8.662c-8.668-.831-17.455-.774-26.229-.096a452.953 452.953 0 00-2.664-8.547z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.50573 -.36757 .62264 .87457 -7.014 110)"
                        ></path>
                    </g>
                    <path
                        id="tripleFive"
                        onClick={() => { onTriple(5) }}
                        fill="#338000"
                        strokeWidth="0.364"
                        d="M128.06 65.34c10.176-.927 20.491-1.06 30.766-.035a374.275 374.275 0 00-2.445 8.613c-8.492-.772-17.095-.698-25.684-.029-.85-2.855-1.73-5.705-2.637-8.549z"
                        className="UnoptimicedTransforms"
                        transform="matrix(.60143 -.19349 .32599 1.0241 16.472 66.126)"
                    ></path>
                    <path
                        id="outerTwenty"
                        onClick={() => { onSingle(20) }}
                        strokeWidth="0.291"
                        d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                        className="UnoptimicedTransforms"
                        transform="matrix(1.0799 0 0 .98256 -7.505 2.652)"
                    ></path>
                    <path
                        id="outerFive"
                        onClick={() => { onSingle(5) }}
                        fill="#ffb380"
                        strokeWidth="0.291"
                        d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                        className="UnoptimicedTransforms"
                        transform="matrix(1.0288 -.32813 .29855 .9361 -50.394 56.61)"
                    ></path>
                    <g strokeWidth="0.291">
                        <path
                            id="outerOne"
                            onClick={() => { onSingle(1) }}
                            fill="#ffb380"
                            d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                            className="UnoptimicedTransforms"
                            transform="matrix(1.0262 .33629 -.30598 .9337 50.678 -36.291)"
                        ></path>
                        <path
                            id="outerEighteen"
                            onClick={() => { onSingle(18) }}
                            d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.87256 .63626 -.57891 .7939 117.6 -55.104)"
                        ></path>
                        <path
                            id="outerFour"
                            onClick={() => { onSingle(4) }}
                            fill="#ffb380"
                            d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.62531 .88044 -.80108 .56894 188.67 -52.476)"
                        ></path>
                        <path
                            id="outerThirteen"
                            onClick={() => { onSingle(13) }}
                            d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                            className="UnoptimicedTransforms"
                            transform="matrix(.33142 1.0278 -.93514 .30155 252.45 -28.235)"
                        ></path>
                        <path
                            id="outerSix"
                            onClick={() => { onSingle(6) }}
                            fill="#ffb380"
                            d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                            className="UnoptimicedTransforms"
                            transform="matrix(-.00285 1.0799 -.98255 -.0026 307.18 14.763)"
                        ></path>
                    </g>
                    <path
                        id="outerTen"
                        onClick={() => { onSingle(10) }}
                        strokeWidth="0.291"
                        d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-.33914 1.0253 -.93285 -.30857 346.26 73.217)"
                    ></path>
                    <path
                        id="outerFifteen"
                        onClick={() => { onSingle(15) }}
                        fill="#ffb380"
                        strokeWidth="0.291"
                        d="M127.44 74.792c8.351-1.226 16.928-1.275 25.428-.023l-3.895 26.09c-5.778-.88-11.785-.826-17.719.035z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-.63147 .87603 -.79706 -.57455 364.34 138.73)"
                    ></path>
                </g>
                <g stroke="url(#linearGradient18513)">
                    <path
                        id="outerSeventeen"
                        onClick={() => { onSingle(17) }}
                        fill="#ffb380"
                        strokeWidth="0.289"
                        d="M127.42 74.661c8.376-1.14 16.954-1.099 25.428.238l-3.895 26.09c-5.738-.874-11.704-.824-17.6.024-1.3-8.785-2.61-17.569-3.932-26.352z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-1.0244 .3418 -.30373 -.95435 337.73 275.07)"
                    ></path>
                    <path
                        id="outerThree"
                        onClick={() => { onSingle(3) }}
                        strokeWidth="0.289"
                        d="M127.42 74.661c8.376-1.14 16.954-1.099 25.428.238l-3.895 26.09c-5.738-.874-11.704-.824-17.6.024-1.3-8.785-2.61-17.569-3.932-26.352z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-1.0799 .01124 .00352 -1.0015 294.79 329.37)"
                    ></path>
                    <path
                        id="outerNineteen"
                        onClick={() => { onSingle(19) }}
                        fill="#ffb380"
                        strokeWidth="0.289"
                        d="M127.42 74.661c8.376-1.14 16.954-1.099 25.428.238l-3.895 26.09c-5.738-.874-11.704-.824-17.6.024-1.3-8.785-2.61-17.569-3.932-26.352z"
                        className="UnoptimicedTransforms"
                        transform="matrix(-1.0293 -.32681 .31633 -.95025 236.48 368.36)"
                    ></path>
                </g>
                <path
                    id="outerSeven"
                    onClick={() => { onSingle(7) }}
                    stroke="url(#linearGradient18513)"
                    strokeWidth="0.289"
                    d="M127.42 74.661c8.376-1.14 16.954-1.099 25.428.238l-3.895 26.09c-5.738-.874-11.704-.824-17.6.024-1.3-8.785-2.61-17.569-3.932-26.352z"
                    className="UnoptimicedTransforms"
                    transform="matrix(-.87791 -.62891 .59452 -.80597 169.46 387.11)"
                ></path>
                <path
                    id="outerTwo"
                    onClick={() => { onSingle(2) }}
                    stroke="gray"
                    strokeWidth="0.289"
                    d="M127.42 74.661c8.376-1.14 16.954-1.099 25.428.238l-3.895 26.09c-5.738-.874-11.704-.824-17.6.024-1.3-8.785-2.61-17.569-3.932-26.352z"
                    className="UnoptimicedTransforms"
                    transform="matrix(-.86758 .64308 -.58513 -.81281 362.04 209.46)"
                ></path>
                <path
                    id="innerTwenty"
                    onClick={() => { onSingle(20) }}
                    strokeWidth="3.817"
                    d="M135.49 111.59l5.64 34.375 1.048-.145 1.12-.036.904-.037 1.157.073 1.048.145 5.422-34.447-2.711-.4-2.639-.18-1.988-.037-2.314.036-1.843.073-1.59.145z"
                ></path>
                <g strokeWidth="3.817">
                    <path
                        id="innerFive"
                        onClick={() => { onSingle(5) }}
                        fill="#ffb380"
                        d="M119.17 117l16.036 30.924.951-.464 1.054-.382.848-.315 1.122-.29 1.042-.188-5.544-34.428-2.7.462-2.566.647-1.9.583-2.189.753-1.73.642-1.466.632z"
                    ></path>
                    <path
                        id="innerOne"
                        onClick={() => { onSingle(1) }}
                        fill="#ffb380"
                        d="M152.85 111.87l-5.612 34.379 1.04.197 1.074.322.868.254 1.073.438.947.471 16.12-30.922-2.442-1.242-2.444-1.013-1.872-.669-2.205-.703-1.77-.518-1.554-.37z"
                    ></path>
                    <path
                        id="innerEighteen"
                        onClick={() => { onSingle(18) }}
                        d="M169.05 117.34l-15.988 30.949.928.51.92.639.748.51.884.748.755.743 24.907-24.406-1.937-1.939-2.01-1.72-1.573-1.216-1.878-1.351-1.523-1.042-1.363-.833z"
                    ></path>
                    <path
                        id="innerFour"
                        onClick={() => { onSingle(4) }}
                        fill="#ffb380"
                        d="M182.97 127.73l-24.798 24.464.724.772.677.893.552.717.61.986.486.94 31.248-15.478-1.24-2.444-1.377-2.259-1.119-1.643-1.366-1.868-1.125-1.462-1.037-1.215z"
                    ></path>
                    <path
                        id="innerThirteen"
                        onClick={() => { onSingle(13) }}
                        d="M192.75 141.6l-31.034 15.822.457.955.375 1.056.31.85.282 1.124.18 1.043 34.466-5.306-.444-2.704-.629-2.57-.57-1.904-.737-2.193-.63-1.735-.622-1.47z"
                    ></path>
                    <path
                        id="innerSix"
                        onClick={() => { onSingle(6) }}
                        fill="#ffb380"
                        d="M197.84 157.88l-34.401 5.48.14 1.048.031 1.12.032.905-.078 1.156-.15 1.048 34.422 5.582.412-2.709.194-2.638.046-1.988-.026-2.313-.064-1.844-.138-1.592z"
                    ></path>
                    <path
                        id="innerTen"
                        onClick={() => { onSingle(10) }}
                        d="M197.81 174.92l-34.409-5.424-.19 1.04-.318 1.076-.249.87-.431 1.075-.467.95 31.01 15.951 1.23-2.449 1-2.449.657-1.876.691-2.209.51-1.773.36-1.556z"
                    ></path>
                    <path
                        id="innerFifteen"
                        onClick={() => { onSingle(15) }}
                        fill="#ffb380"
                        d="M192.28 191.26l-31.096-15.699-.5.933-.631.926-.504.752-.74.892-.735.761 24.637 24.68 1.92-1.956 1.702-2.025 1.2-1.585 1.335-1.89 1.027-1.533.82-1.37z"
                    ></path>
                    <path
                        id="innerTwo"
                        onClick={() => { onSingle(2) }}
                        d="M182.22 204.9l-24.778-24.484-.762.734-.885.688-.71.561-.978.622-.933.499 15.875 31.049 2.427-1.272 2.241-1.405 1.63-1.14 1.85-1.39 1.448-1.143 1.2-1.053z"
                    ></path>
                    <path
                        id="innerSeventeen"
                        onClick={() => { onSingle(17) }}
                        fill="#ffb380"
                        d="M168.02 215.15l-15.6-31.146-.958.45-1.059.368-.852.303-1.126.274-1.044.173 5.06 34.503 2.707-.424 2.573-.611 1.91-.556 2.198-.722 1.738-.618 1.476-.61z"
                    ></path>
                    <path
                        id="innerThree"
                        onClick={() => { onSingle(3) }}
                        d="M151.8 220.14l-5.42-34.41-1.05.139-1.12.029-.905.03-1.156-.08-1.048-.151-5.64 34.412 2.708.416 2.638.198 1.988.05 2.313-.023 1.844-.06 1.592-.136z"
                    ></path>
                    <path
                        id="innerNineteen"
                        onClick={() => { onSingle(19) }}
                        fill="#ffb380"
                        d="M134.71 219.97l5.514-34.395-1.04-.194-1.075-.32-.869-.25-1.074-.435-.949-.469-16.032 30.968 2.446 1.236 2.446 1.006 1.875.663 2.207.696 1.772.514 1.554.365z"
                    ></path>
                    <path
                        id="innerSeven"
                        onClick={() => { onSingle(7) }}
                        d="M118.39 214.71l15.873-31.008-.93-.506-.923-.636-.749-.507-.887-.745-.758-.74-24.817 24.498 1.945 1.931 2.015 1.713 1.579 1.21 1.883 1.344 1.526 1.037 1.366.827z"
                    ></path>
                    <path
                        id="innerSixteen"
                        onClick={() => { onSingle(16) }}
                        fill="#ffb380"
                        d="M104.51 204.41l24.74-24.523-.726-.77-.68-.892-.553-.715-.611-.985-.49-.938-31.212 15.552 1.247 2.44 1.382 2.256 1.123 1.64 1.37 1.864 1.129 1.46 1.04 1.212z"
                    ></path>
                    <path
                        id="innerEight"
                        onClick={() => { onSingle(8) }}
                        d="M94.502 190.39l31.183-15.526-.448-.959-.365-1.06-.301-.853-.272-1.127-.17-1.044-34.515 4.978.418 2.708.605 2.575.552 1.91.717 2.2.613 1.74.608 1.478z"
                    ></path>
                    <path
                        id="innerEleven"
                        onClick={() => { onSingle(11) }}
                        fill="#ffb380"
                        d="M89.478 174.2l34.38-5.609-.144-1.048-.035-1.12-.036-.904.074-1.157.146-1.048-34.443-5.453-.402 2.71-.184 2.64-.038 1.987.035 2.314.07 1.844.145 1.59z"
                    ></path>
                    <path
                        id="innerFourteen"
                        onClick={() => { onSingle(14) }}
                        d="M89.503 156.93l34.406 5.444.191-1.041.318-1.075.25-.87.432-1.075.467-.95-31.001-15.968-1.23 2.449-1.002 2.448-.659 1.876-.692 2.208-.51 1.773-.362 1.556z"
                    ></path>
                </g>
                <path
                    id="innerNine"
                    onClick={() => { onSingle(9) }}
                    fill="#ffb380"
                    strokeWidth="3.841"
                    d="M94.811 140.78l31.289 15.86.506-.936.639-.93.509-.754.748-.895.742-.764-24.759-24.87-1.94 1.96-1.719 2.032-1.214 1.59-1.348 1.897-1.04 1.538-.829 1.376z"
                ></path>
                <path
                    id="innerTwelve"
                    onClick={() => { onSingle(12) }}
                    strokeWidth="3.841"
                    d="M105.12 126.86l24.846 24.763.771-.734.895-.686.717-.56.988-.62.943-.496-15.848-31.312-2.451 1.264-2.264 1.4-1.646 1.137-1.87 1.387-1.464 1.141-1.214 1.052z"
                ></path>
                <g fill="gray" strokeWidth="3.809" fontSize="16.933">
                    <g fontFamily="sans-serif">
                        <text
                            x="133.607"
                            y="58.995"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="133.607" y="58.995">
                                20
                            </tspan>
                        </text>
                        <text
                            x="104.515"
                            y="65.151"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="104.515" y="65.151">
                                5
                            </tspan>
                        </text>
                        <text
                            x="67.898"
                            y="80.061"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="67.898" y="80.061">
                                12
                            </tspan>
                        </text>
                        <text
                            x="48.812"
                            y="104.353"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="48.812" y="104.353">
                                9
                            </tspan>
                        </text>
                        <text
                            x="25.387"
                            y="137.031"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="25.387" y="137.031">
                                14
                            </tspan>
                        </text>
                        <text
                            x="21.463"
                            y="171.594"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="21.463" y="171.594">
                                11
                            </tspan>
                        </text>
                    </g>
                    <text
                        x="70.563"
                        y="76.057"
                        fontFamily="Candara"
                        style={{
                            fontVariantCaps: "normal",
                            fontVariantEastAsian: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantNumeric: "normal",
                        }}
                    ></text>
                    <text
                        x="67.96"
                        y="78.371"
                        fontFamily="Candara"
                        style={{
                            fontVariantCaps: "normal",
                            fontVariantEastAsian: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantNumeric: "normal",
                        }}
                    ></text>
                    <g fontFamily="sans-serif">
                        <text
                            x="33.03"
                            y="206.875"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="33.03" y="206.875">
                                8
                            </tspan>
                        </text>
                        <text
                            x="41.706"
                            y="237.819"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="41.706" y="237.819">
                                16
                            </tspan>
                        </text>
                        <text
                            x="74.096"
                            y="264.135"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="74.096" y="264.135">
                                7
                            </tspan>
                        </text>
                        <text
                            x="98.677"
                            y="278.306"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="98.677" y="278.306">
                                19
                            </tspan>
                        </text>
                        <text
                            x="138.585"
                            y="284.379"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="138.585" y="284.379">
                                3
                            </tspan>
                        </text>
                        <text
                            x="167.794"
                            y="278.884"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="167.794" y="278.884">
                                17
                            </tspan>
                        </text>
                        <text
                            x="205.389"
                            y="260.665"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="205.389" y="260.665">
                                2
                            </tspan>
                        </text>
                        <text
                            x="224.764"
                            y="236.662"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="224.764" y="236.662">
                                15
                            </tspan>
                        </text>
                        <text
                            x="240.092"
                            y="205.43"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="240.092" y="205.43">
                                10
                            </tspan>
                        </text>
                        <text
                            x="250.792"
                            y="171.016"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="250.792" y="171.016">
                                6
                            </tspan>
                        </text>
                        <text
                            x="239.802"
                            y="137.759"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="239.802" y="137.759">
                                13
                            </tspan>
                        </text>
                        <text
                            x="229.102"
                            y="107.683"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="229.102" y="107.683">
                                4
                            </tspan>
                        </text>
                        <text
                            x="200.472"
                            y="82.234"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="200.472" y="82.234">
                                18
                            </tspan>
                        </text>
                        <text
                            x="174.156"
                            y="66.039"
                            style={{
                                fontVariantCaps: "normal",
                                fontVariantEastAsian: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantNumeric: "normal",
                            }}
                        >
                            <tspan x="174.156" y="66.039">
                                1
                            </tspan>
                        </text>
                    </g>
                </g>
                <path
                    id="singleBull"
                    onClick={() => { onSingle(25) }}
                    fill="#338000"
                    stroke="gray"
                    strokeWidth="0.618"
                    d="M163.708 166.054a20 20 0 01-20 20 20 20 0 01-20-20 20 20 0 0120-20 20 20 0 0120 20z"
                ></path>
                <path
                    id="doubleBull"
                    onClick={() => { onDouble(25) }}
                    fill="#de0000"
                    stroke="gray"
                    strokeWidth="0.6"
                    d="M153.705 166.045a10 10 0 01-10 10 10 10 0 01-10-10 10 10 0 0110-10 10 10 0 0110 10z"
                ></path>
                <g fill="gray" strokeWidth="0">
                    <path d="M45.403 150.31l78.371 12.508M55.196 120.8l70.497 36.45M73.283 95.538l56.084 56.152M98.446 77.376l36.261 70.594"></path>
                </g>
                <g fill="red" strokeWidth="0">
                    <path d="M128.33 67.658l12.258 78.41M159.4 67.847l-12.586 78.358M189.03 77.404l-36.405 70.52M214.01 95.745l-56.096 56.14M241.85 150.1l-78.301 12.939M242.2 181.26l-78.43-12.131M232.56 210.99l-70.854-35.752M214.11 236.69l-55.992-56.244M189.21 254.72l-36.4-70.523M159.54 264.42l-12.393-78.389M128.11 264.45l12.591-78.358M98.637 254.84l35.702-70.879M73.214 236.5l56.24-55.995"></path>
                </g>
                <path fill="#f0f" strokeWidth="0" d="M45.376 181.49l78.401-12.32"></path>
            </svg>
        </div>
    )
}

export default DartboardComponent