import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { useRecoilState } from 'recoil';
import Player from '../models/Player';
import { playersState } from './atoms';


const PlayersListComponent = () => {

    const [players, setPlayers] = useRecoilState(playersState)

    const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, p: Player) => {
        e.stopPropagation();

        let editablePlayers = players.map((p) => {
            return new Player(p.id, p.name, p.initialStartingOrder, []);
        });

        editablePlayers = editablePlayers.filter(function (el) {
            return el.name !== p.name;
        })

        editablePlayers.sort((a, b) => (a.initialStartingOrder < b.initialStartingOrder ? -1 : 1));

        setPlayers(editablePlayers.map((p, index) => {
            return new Player(p.id, p.name, index, []);
        }))
    }

    const onUp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, playerToMoveUp: Player) => {
        e.stopPropagation();
        if (playerToMoveUp.initialStartingOrder - 1 < 0) {
            return;
        }

        const playerToMoveDown = getPlayer(playerToMoveUp.initialStartingOrder - 1)

        setPlayers(players.map((p) => {

            if (p === playerToMoveUp) {
                return new Player(p.id, p.name, p.initialStartingOrder - 1, []);
            } else if (p === playerToMoveDown) {
                return new Player(p.id, p.name, p.initialStartingOrder + 1, []);
            }
            return new Player(p.id, p.name, p.initialStartingOrder, []);
        }));
    }

    const onDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, playerToMoveDown: Player) => {
        e.stopPropagation();
        if (playerToMoveDown.initialStartingOrder + 1 >= players.length) {
            return;
        }

        const playerToMoveUp = getPlayer(playerToMoveDown.initialStartingOrder + 1)

        setPlayers(players.map((p) => {

            if (p === playerToMoveDown) {
                return new Player(p.id, p.name, p.initialStartingOrder + 1, []);
            } else if (p === playerToMoveUp) {
                return new Player(p.id, p.name, p.initialStartingOrder - 1, []);
            }
            return new Player(p.id, p.name, p.initialStartingOrder, []);
        }));
    }

    const getPlayer = (initalStartingOrder: number): Player | null => {
        const player = players.find((p) => {
            return p.initialStartingOrder === initalStartingOrder
        });

        return player != null ? player : null;
    }

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', width: 275, sortable: false },
        { field: 'initialStartingOrder', type: "number", headerName: 'Order', width: 200, sortable: false, sortComparator: (v1, v2) => parseInt(v1) - parseInt(v2) },
        {
            field: 'actions', headerName: '', width: 135, sortable: false, renderCell: (params) => {
                return (
                    <div>
                        <IconButton onClick={(e) => onDown(e, params.row)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton onClick={(e) => onUp(e, params.row)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={(e) => onDelete(e, params.row)}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            }
        }
    ];

    return (
        <div style={{ width: '100%' }}>
            <DataGrid
                sx={{
                    fontSize: 30,
                    '&>.MuiDataGrid-main': {
                        '&>.MuiDataGrid-columnHeaders': {
                            borderBottom: 'none',
                        },
                        '& div div div div >.MuiDataGrid-cell': {
                            borderBottom: 'none',
                        }
                    }
                }}
                getRowId={(row) => row?.name}
                rows={players}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'initialStartingOrder', sort: 'asc' }],
                    },
                    columns: { columnVisibilityModel: { initialStartingOrder: false } }
                }}
                headerHeight={0}
                columns={columns}
                disableColumnMenu
                disableSelectionOnClick
                autoHeight
                hideFooter={true}
                components={{
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            NO PLAYERS
                        </Stack>
                    )
                }}
            />
        </div>
    )
}

export default PlayersListComponent