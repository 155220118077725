import { Box, Stack, Divider, Typography } from '@mui/material'
import React from 'react'
import Player from '../../../models/Player'
import StandardGameCurrentPlayerStatsComponent from './StandardGameCurrentPlayerStatsComponent'


type StatsProps = {
    players: Player[]
    startingPoints: number
}

const StandardGameStatsComponent = ({ players, startingPoints }: StatsProps) => {
    return (
        <div>
            <Stack spacing={1} direction="row" justifyContent="center">
                <Box sx={{ width: 175 }}>
                    <Stack
                        direction="column"
                        // justifyContent="flex-start"
                        alignItems="center"
                    // spacing={1}
                    >
                        <div style={{ height: 37.5 }}></div>
                        {/* <Typography fontSize={25}>NAME</Typography> */}
                        {/* <Divider style={{ width: '100%' }} /> */}
                        <Divider style={{ width: '100%', visibility: 'hidden' }} />
                        <Typography fontSize={25}>MATCH AVG</Typography>
                        <Divider style={{ width: '100%' }} />
                        <Typography fontSize={25}>180</Typography>
                        <Typography fontSize={25}>140+</Typography>
                        <Typography fontSize={25}>100+</Typography>
                        <Typography fontSize={25}>80+</Typography>
                        <Divider style={{ width: '100%' }} />
                        <div>
                            <Box
                                sx={{ fontSize: 25, width: 170 }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                HIGH FINISHES
                                {/* <Typography fontSize={25}>HIGH FINISHES</Typography> */}
                            </Box>
                        </div>
                        {/* <Divider style={{ width: '100%' }} /> */}
                    </Stack>
                </Box>
                {
                    players.map(player => {
                        return <StandardGameCurrentPlayerStatsComponent player={player} startingPoints={startingPoints} />
                    })
                }
            </Stack>
        </div>
    )
}

export default StandardGameStatsComponent