import { InputLabel, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useRef, useState } from 'react';


type CheckoutProps = {
    toggle: () => void;
    onConfirm: (amountOfDarts: number) => void;
    dartsMinimumRequired: number;
}

const StandardGameCheckoutComponent = ({ toggle, onConfirm, dartsMinimumRequired }: CheckoutProps) => {

    const [darts, setDarts] = useState("");
    const inputReference = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputReference.current?.focus();
    }, []);

    const onValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;

        if (value === "") {
            setDarts("0")
        }

        else if (value == null || !Number.isFinite(Number(value)) || value.includes(' ') || Number(value) === 0 || Number(value) > 3 || Number(value) < dartsMinimumRequired) {
            return;
        }

        setDarts(value)
    }

    const onValueEntered = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            if (Number(darts) === 1 || Number(darts) === 2 || Number(darts) === 3) {
                toggle()
                onConfirm(Number(darts));
            }
        }
    }

    return (
        <div>
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="flex-start"
            >
                <InputLabel sx={{ fontSize: "30px" }}>
                    DARTS TO CHECK OUT
                </InputLabel>
                <TextField
                    id="require-darts-input"
                    variant="standard"
                    inputProps={{ style: { textAlign: 'center', fontSize: "64px", width: "200px" } }}
                    value={darts}
                    ref={inputReference}
                    type="text"
                    name="requiredDarts"
                    autoFocus
                    onChange={onValueChanged}
                    onKeyUp={onValueEntered}
                />
            </Stack>
        </div>

    )
}

export default StandardGameCheckoutComponent