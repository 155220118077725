import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import HistoryEntry from '../models/HistoryEntry';

type HistoryProps = {
    history: HistoryEntry[]
}

const HistoryComponent = ({ history }: HistoryProps) => {

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 100
        },
        {
            field: 'name',
            headerName: 'NAME',
            width: 230
        },
        {
            field: 'score',
            headerName: 'SCORE',
            width: 70,
            type: 'number',
            align: 'right'
        }
    ]

    return (
        <Box sx={{ height: 600, width: 320, backgroundColor: 'white' }}>
            <DataGrid
                sx={{
                    fontSize: 25,
                    '&>.MuiDataGrid-main': {
                        '&>.MuiDataGrid-columnHeaders': {
                            borderBottom: 'none',
                        },
                        '& div div div div >.MuiDataGrid-cell': {
                            borderBottom: 'none',
                        }
                    }
                }}
                rows={history}
                columns={columns}
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                disableSelectionOnClick
                hideFooter
                headerHeight={0}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'desc' }],
                    },
                    columns: { columnVisibilityModel: { id: false } }
                }}
                components={{
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            NO DARTS THROWN
                        </Stack>
                    )
                }}
            />
        </Box>
    )
}

export default HistoryComponent