import { AxiosResponse } from "axios";
import http from "../http-common";
import PlayerMasterData from "../models/PlayerMasterData";
import AuthService from "./AuthService";

class PlayerService {

    getAll(): Promise<AxiosResponse<PlayerMasterData[], any>> {
        return http.get<Array<PlayerMasterData>>("/player/all", AuthService.getAuthConfig());
    }

    save(players: PlayerMasterData[]): Promise<AxiosResponse<PlayerMasterData[], any>> {
        return http.put<Array<PlayerMasterData>>("/player/update", players, AuthService.getAuthConfig());
    }

    deleteByIds(ids: string[]): Promise<AxiosResponse<string[], any>> {
        return http.delete<Array<string>>("/player/delete", { data: ids, headers: { Authorization: AuthService.createBearerToken() } });
    }

}

export default new PlayerService();