export class GameSettings {

    id: string | null;
    rankingMode: boolean;

    constructor(rankingMode: boolean){
        this.id = null;
        this.rankingMode = rankingMode;
    }

}