import DartsThrow from "../common/DartsThrow";

class AroundTheClockLeg {

    id?: string | null;
    currentScore: number;
    started: boolean;
    amountThrownDarts: number;
    dartsThrow: DartsThrow[];
    amountOfBulls: number;
    won: boolean;
    finished: boolean;
    position: number;

    constructor(started: boolean) {
        this.id = null;
        this.currentScore = 1;
        this.started = started;
        this.amountThrownDarts = 0;
        this.dartsThrow = [];
        this.amountOfBulls = 0;
        this.won = false;
        this.finished = false;
        this.position = null;
    }

    currentThrow(): DartsThrow | null {
        if (this.dartsThrow.length === 0) {
            this.dartsThrow.push(new DartsThrow());
        }

        return this.dartsThrow[this.dartsThrow.length - 1];
    }
}

export default AroundTheClockLeg