import FlagIcon from '@mui/icons-material/Flag';
import { Grid, InputLabel } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import React from 'react';
import { useRecoilValue } from 'recoil';
import Player from '../../../models/Player';
import { getDoubleCheckout } from '../../../types/DoubleOutOption';
import { GameMode } from '../../../types/GameMode';
import { standardGameState } from '../../atoms';
import StandardGameDoubleOutOptionComponent from './StandardGameDoubleOutOptionComponent';
import StandardGameLegsComponent from './StandardGameLegsComponent';
import './standardGamePlayer.css';
import StandardGameSetsComponent from './StandardGameSetsComponent';
import { NineDarterOptions } from '../../../types/NineDarterOption';


type PlayerProps = {
    player: Player
    active: boolean
    lastScore: number
    startingPoints: number
}

const StandardGamePlayerComponent = ({ player, active, lastScore, startingPoints }: PlayerProps) => {

    const standardGame = useRecoilValue(standardGameState)

    const isNineDarterPossible = (player: Player): boolean => {
        if (startingPoints !== 501) {
            return false;
        }

        const score = player.currentLeg().currentScore;
        const thrownDarts = player.currentLeg().thrownDarts;
        if (score === 501) {
            return false;
        }

        if (thrownDarts === 3) {
            return NineDarterOptions.find(o => {
                return (o.round1 + score === 501) ? true : false;
            }) != null;
        }

        if (thrownDarts === 6) {
            return NineDarterOptions.find(o => {
                return o.round3 === score
            }) != null;
        }

        return false;
    }

    return (
        <div>
            <Grid item xs={16}>
                <div className={"player-main-container"}>
                    <Box sx={{ border: 1 }}>
                        <div className={active ? "active-player" : ""}>
                            <div className="player-container-with-highlighting">
                                <div className="one-item">
                                    {/* <InputLabel sx={{ fontSize: 30, color: 'black' }}>{player.name}</InputLabel> */}
                                    <Stack
                                        sx={{ width: 348 }}
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Box width={40}>
                                            {
                                                player.currentLeg().started && <FlagIcon sx={{ fontSize: 30 }} />
                                            }
                                        </Box>
                                        <Box>
                                            <InputLabel sx={{ fontSize: 30, color: 'black' }}>{player.name}</InputLabel>
                                        </Box>
                                        <Box width={40}>
                                            {
                                                isNineDarterPossible(player) &&
                                                <InputLabel sx={{ width: 75, fontSize: 30, background: 'yellow', color: 'black' }}>9</InputLabel>
                                            }
                                        </Box>
                                    </Stack>
                                </div>
                            </div>
                            <Divider />
                            <div className="player-container">
                                <div className="first-out-of-three">
                                    <Box sx={{
                                        display: "flex",
                                        width: "114px",
                                        height: "86px",
                                        alignItems: "center",
                                        justifyContent: "left"
                                    }}>
                                        {
                                            // PLACEHOLDER
                                        }
                                    </Box>
                                </div>
                                <div className="second-out-of-three">
                                    <InputLabel sx={{ fontSize: "60px", color: 'black' }}>{player.currentLeg()?.currentScore}</InputLabel>
                                </div>
                                <div className="third-out-of-three">
                                    <Box sx={{
                                        display: "flex",
                                        width: "114px",
                                        height: "86px",
                                        alignItems: "center",
                                        justifyContent: "right"
                                    }}>
                                        {
                                            // PLACEHOLDER
                                        }
                                    </Box>
                                </div>
                            </div>
                            <Divider />
                            {
                                standardGame.gameMode === GameMode.Sets ?
                                    (<StandardGameSetsComponent wonSets={player.wonSets} wonLegs={player.wonLegs} />) :
                                    (<StandardGameLegsComponent wonLegs={player.wonLegs} />)
                            }
                            <Divider />
                            <div className="player-container">
                                <Stack
                                    sx={{ width: 348 }}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-evenly"
                                    divider={<Divider orientation="vertical" flexItem />}
                                    margin={-0.5}
                                >
                                    <Stack>
                                        <Box sx={{
                                            display: "flex",
                                            // width: "175px",
                                            height: "77px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}>
                                            <Stack
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={0}
                                            >
                                                <InputLabel sx={{ fontSize: 20 }}>LEG AVG</InputLabel>
                                                <InputLabel sx={{ width: 145, margin: -1, fontSize: 35, color: 'black' }}>{player.currentLeg()?.calculateThreeDartLegAverage(standardGame.startingPoints)}</InputLabel>
                                            </Stack>
                                        </Box>
                                    </Stack>
                                    <Stack>
                                        <Box sx={{
                                            display: "flex",
                                            // width: "175px",
                                            height: "77px",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <Stack
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={0}
                                            >
                                                <InputLabel sx={{ fontSize: 20 }}>DARTS</InputLabel>
                                                <InputLabel sx={{ margin: -1, fontSize: 35, color: 'black' }}>{player.currentLeg()?.thrownDarts}</InputLabel>
                                            </Stack>

                                        </Box>
                                    </Stack>
                                    <Stack>
                                        <Box sx={{
                                            display: "flex",
                                            // width: "175px",
                                            height: "77px",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <Stack
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={0}
                                            >
                                                <InputLabel sx={{ fontSize: 20 }}>LAST SCORE</InputLabel>
                                                <InputLabel sx={{ margin: -1, fontSize: 35, color: 'black' }}>{lastScore}</InputLabel>
                                            </Stack>

                                        </Box>
                                    </Stack>
                                </Stack>
                            </div>
                            <Divider />
                            <div>
                                {
                                    getDoubleCheckout(player.currentLeg().currentScore) != null &&
                                    getDoubleCheckout(player.currentLeg().currentScore).map((option) => {
                                        return <StandardGameDoubleOutOptionComponent option={option}></StandardGameDoubleOutOptionComponent>
                                    })
                                }
                            </div>
                        </div>
                    </Box>
                </div>
            </Grid >
        </div >
    )
}

export default StandardGamePlayerComponent