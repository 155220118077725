import { Container, Divider, Stack } from '@mui/material'
import React from 'react'
import EnterPlayerComponent from './EnterPlayerComponent'
import GameSettingsComponent from './GameSettingsComponent'
import PlayersListComponent from './PlayersListComponent'

const SettingsComponent = () => {
  return (
    <div>
      <Container maxWidth='xl'>
        <Stack
          margin={2}
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack
            sx={{ width: 420 }}
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
          >
            <EnterPlayerComponent />
            <PlayersListComponent />
          </Stack>
          <GameSettingsComponent />
        </Stack>
      </Container>
    </div>
  )
}

export default SettingsComponent