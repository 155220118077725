import AroundTheClockLeg from "./AroundTheClockLeg";

class AroundTheClockPlayer {

    id: string | null;
    name: string;
    initialStartingOrder: number;
    legs: AroundTheClockLeg[];
    wonGames: number;

    constructor(id: string, name: string, initialStartingOrder: number, legs: AroundTheClockLeg[]) {
        this.id = id;
        this.name = name;
        this.initialStartingOrder = initialStartingOrder;
        this.legs = legs;
        this.wonGames = 0;
    }

    currentLeg(): AroundTheClockLeg | null {
        if (this.legs == null || this.legs.length === 0) {
            return null;
        }

        return this.legs[this.legs.length - 1];
    }
}

export default AroundTheClockPlayer