import Leg from "./Leg";

class Player {

    id: string | null;
    name: string;
    initialStartingOrder: number;
    legs: Leg[];
    wonLegs: number;
    wonSets: number;

    constructor(id: string, name: string, initialStartingOrder: number, legs: Leg[]) {
        this.id = id;
        this.name = name;
        this.initialStartingOrder = initialStartingOrder;
        this.legs = legs;
        this.wonLegs = 0;
        this.wonSets = 0;
    }

    currentLeg(): Leg | null {
        if (this.legs == null || this.legs.length === 0) {
            return null;
        }

        return this.legs[this.legs.length - 1];
    }

    getScoredAmount180(): number {
        let amount = 0;
        this.legs.forEach(leg => amount += leg.scoredAmount180);
        return amount;
    }

    getScoredAmountBetween140And179(): number {
        let amount = 0;
        this.legs.forEach(leg => amount += leg.scoredAmountBetween140And179);
        return amount;
    }

    getScoredAmountBetween100And139(): number {
        let amount = 0;
        this.legs.forEach(leg => amount += leg.scoredAmountBetween100And139);
        return amount;
    }

    getScoredAmountBetween80And99(): number {
        let amount = 0;
        this.legs.forEach(leg => amount += leg.scoredAmountBetween80And99);
        return amount;
    }

    getAllHighFinishes(): string {
        const highFinishes: number[] = [];
        this.legs.forEach(leg => {
            if (leg.checkout != null && leg.checkout >= 100) {
                highFinishes.push(leg.checkout);
            }
        });
        return highFinishes.join(", ");
    }

    calculateMatchAverage(startingPoints: number): string {
        let thrownDarts = 0;
        let scoredPoints = 0;
        this.legs.forEach(leg => {
            thrownDarts += leg.thrownDarts;
            scoredPoints += (startingPoints - leg.currentScore);
        })
        if (scoredPoints === 0 || thrownDarts === 0) {
            return "0";
        }
        return ((scoredPoints / thrownDarts) * 3).toFixed(2);
    }
}

export default Player