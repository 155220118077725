import AnalyticsIcon from '@mui/icons-material/Analytics';
import HistoryIcon from '@mui/icons-material/History';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, ButtonGroup, Container, Fade, Grid, IconButton, InputLabel, Modal, Snackbar, TextField, Typography } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Stack from '@mui/system/Stack';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import ErrorResponseHandler from '../../../helper/ErrorResponseHandler';
import '../../../index.css';
import HistoryEntry from '../../../models/HistoryEntry';
import Leg from '../../../models/Leg';
import Player from '../../../models/Player';
import StandardGameOverview from '../../../models/StandardGameOverview';
import StandardGameService from '../../../services/StandardGameService';
import { CheckoutMode } from '../../../types/CheckoutMode';
import { getMinimumRequiredDartsForCheckout, isDoubleOutPossible } from '../../../types/DoubleOutOption';
import { GameMode } from '../../../types/GameMode';
import { playersState, standardGameState } from '../../atoms';
import HistoryComponent from '../../HistoryComponent';
import StandardGameCheckoutComponent from './StandardGameCheckoutComponent';
import StandardGamePlayerComponent from './StandardGamePlayerComponent';
import StandardGameStatsComponent from './StandardGameStatsComponent';

const invalidScoresUnder180 = [163, 166, 169, 172, 173, 175, 176, 178, 179];

const StandardGameComponent = () => {

    const standardGame = useRecoilValue(standardGameState);
    const configuredPlayers = useRecoilValue(playersState);
    const [players, setPlayers] = useState<Player[]>([]);
    const [currentScore, setCurrentScore] = useState<string>();
    const [winner, setWinner] = useState<Player>();
    const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
    const [isDoubleOut, setIsDoubleOut] = useState(false);
    const [isDoubleOutError, setIsDoubleOutError] = useState(false);
    const [dartsMinimumRequired, setDartsMinimumRequired] = useState<number>();
    const [isWonModalOpen, setIsWonModalOpen] = useState(false);
    const playerIndex = useRef(0);
    const previousPlayerIndex = useRef<number>();
    const currentScoreInput = useRef<HTMLInputElement>();
    const [isHistoryOpen, setIsHistoryOpen] = useState(false)
    const [history, setHistory] = useState<HistoryEntry[]>([]);
    const [isStatisticOpen, setIsStatisticOpen] = useState(false)
    const [invalidScore, setInvalidScore] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();

    const toggleCheckoutModal = () => {
        setIsCheckoutModalOpen(!isCheckoutModalOpen);
    };

    const toggleWonModal = () => {
        setIsWonModalOpen(!isWonModalOpen);
    };

    useEffect(() => {
        setPlayers(configuredPlayers.map((p) => {
            return new Player(p.id, p.name, p.initialStartingOrder, [new Leg({ currentScore: standardGame.startingPoints, started: p.initialStartingOrder === 0 })]);
        }));
        playerIndex.current = 0;
    }, []);

    const title = (): string => {
        if (standardGame.rankingMode) {
            return "RANKING MODE"
        }
        else if (standardGame.gameMode === GameMode.Legs) {
            return "FIRST TO " + standardGame.legs + " LEGS"
        } else {
            return "FIRST TO " + standardGame.sets + " SETS"
        }
    }

    const onScoreChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value

        if (!isEnteredScoreValid(value)) {
            return
        }

        setCurrentScore(value);
    }

    const isEnteredScoreValid = (score?: string): boolean => {
        if (score == null || !Number.isFinite(Number(score)) || score.includes(' ') || Number(score) > 180) {
            return false;
        }
        return true;
    }

    const onScoreEntered = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            if (!isEnteredScoreValid(currentScore)) {
                return;
            }
            if (invalidScoresUnder180.includes(Number(currentScore))) {
                setInvalidScore(currentScore);
                return;
            }

            for (var i = 0; i < players.length; i++) {
                if (i === playerIndex.current) {
                    const currentPlayer = players[i];
                    const currentLeg = currentPlayer.currentLeg();

                    setIsDoubleOut(isDoubleOutPossible(currentLeg.currentScore))

                    const newScore = currentLeg.currentScore - Number(currentScore);
                    if (newScore < 0) {
                        return;
                    }
                    if (standardGame.checkoutMode === CheckoutMode.Double && newScore === 1) {
                        return;
                    }

                    setCurrentScore("");

                    if (newScore === 0 && !isDoubleOutPossible(currentLeg.currentScore)) {
                        setIsDoubleOutError(true);
                        return;
                    }
                    setIsDoubleOutError(false);

                    if (newScore === 0 && isDoubleOutPossible(currentLeg.currentScore)) {
                        setDartsMinimumRequired(getMinimumRequiredDartsForCheckout(currentLeg.currentScore))
                        setIsCheckoutModalOpen(true)
                        return;
                    }

                    currentLeg.currentScore = newScore;
                    currentLeg.setThrownDarts(3);
                    currentLeg.trackIfHighScore(Number(currentScore));
                    addHistoryEntry(currentPlayer.name, Number(currentScore));

                    setNextPlayer();
                    break;
                }
            }
        }
    }

    const addHistoryEntry = (name: string, score: number) => {
        const entry: HistoryEntry = {
            id: history.length,
            name: name,
            score: score,
        }

        setHistory(history.concat(entry));
    }


    const onUndo = () => {

        currentScoreInput.current.focus();

        if (history.length === 0) {
            return;
        }

        const lastHistoryEntry = history[history.length - 1];
        const lastPlayer = players.find(p => p.name === lastHistoryEntry.name);
        if (lastPlayer.currentLeg().currentScore === 0) {
            return;
        }

        setPreviousPlayerIndex();

        const previousPlayer = players[previousPlayerIndex.current];

        if (lastHistoryEntry.name !== previousPlayer.name) {
            return;
        }

        removeLastHistoryEntry();

        previousPlayer.currentLeg().currentScore += lastHistoryEntry.score;
        previousPlayer.currentLeg().undoIfHighScore(lastHistoryEntry.score);
        previousPlayer.currentLeg().thrownDarts -= 3;
        playerIndex.current = previousPlayerIndex.current;
    }

    const removeLastHistoryEntry = () => {
        setHistory(history.filter((entry, index) => {
            if (index !== history.length - 1) {
                return entry;
            }
        }))
    }

    const setPreviousPlayerIndex = () => {
        if (players.length === 1) {
            // Index always 0 if only one player.
            previousPlayerIndex.current = 0;
        } else {
            // Take the current index minus 1.
            // If value is negative start with last player in the list.
            let index = playerIndex.current - 1;
            if (index < 0) {
                index = players.length - 1;
            }
            previousPlayerIndex.current = index;

            // Repeat if the selected player has already finished the current leg.
            if (players[previousPlayerIndex.current].currentLeg().currentScore === 0) {
                playerIndex.current = index;
                setPreviousPlayerIndex();
            }
        }
    }

    const legFinished = (amountOfDarts: number) => {
        const currentPlayer = players[playerIndex.current];
        const currentLeg = currentPlayer.currentLeg()
        currentLeg.setThrownDarts(amountOfDarts);

        if (currentLeg != null) {
            addHistoryEntry(currentPlayer.name, currentLeg.currentScore);
            currentLeg.trackIfHighScore(currentLeg.currentScore);
            currentLeg.checkout = currentLeg.currentScore;
            currentLeg.currentScore = 0;
        }

        if (!standardGame.rankingMode) {
            currentPlayer.wonLegs++;
            isGameOver(currentPlayer);

            const indexOfPlayerStartedLeg = players.findIndex((p) => {
                return p.currentLeg()?.started
            })

            const newFirstPlayerIndex = (indexOfPlayerStartedLeg + 1) % players.length;
            playerIndex.current = newFirstPlayerIndex;
            initNewLeg(newFirstPlayerIndex);
        } else {
            const playerIndicesWithOpenScore = players.filter(p => { return p.currentLeg()?.currentScore !== 0 })
                .map((p) => { return players.indexOf(p) })

            if (players.length - 1 === playerIndicesWithOpenScore.length) {
                currentPlayer.wonLegs++;
            }

            setNextPlayer()

            if (playerIndicesWithOpenScore != null && playerIndicesWithOpenScore.length === 1) {
                saveStats(currentPlayer.id)
                initNewLeg(playerIndex.current);
            }
        }
    }

    const isGameOver = (currentPlayer: Player) => {
        if (!standardGame.rankingMode) {
            if (currentPlayer.wonLegs === standardGame.legs) {
                if (standardGame.gameMode === GameMode.Legs) {
                    setWinner(currentPlayer);
                    saveStats(currentPlayer.id);
                    toggleWonModal();
                } else if (standardGame.gameMode === GameMode.Sets) {
                    currentPlayer.wonSets++;
                    players.forEach(p => p.wonLegs = 0);
                    if (currentPlayer.wonSets === standardGame.sets) {
                        setWinner(currentPlayer);
                        saveStats(currentPlayer.id);
                        toggleWonModal();
                    }
                }
            }
        }
    }

    const saveStats = async (winnderId: string) => {
        const stats = new StandardGameOverview(standardGame, players, winnderId);

        await StandardGameService.save(stats).catch((error) => {
            ErrorResponseHandler.process(error, "Cannot save stats", setErrorMessage, () => saveStats(winnderId));
        })
    }

    const initNewLeg = (firstPlayerIndex: number) => {
        players.forEach((p, index) => {
            p.legs.push(new Leg({ currentScore: standardGame.startingPoints, started: index === firstPlayerIndex }));
        })
        setHistory([]);
        setPlayers(players);
    }

    const setNextPlayer = () => {
        if (players.length > 1) {
            const newPlayerIndex = (playerIndex.current + 1) % players.length;

            playerIndex.current = newPlayerIndex;

            if (players[newPlayerIndex].legs[players[newPlayerIndex].legs.length - 1].currentScore === 0) {
                setNextPlayer();
            }
        }
    }

    const getLastScore = (player: Player): number => {
        let lastEntry = history.concat().reverse().find(h => h.name === player.name);
        return lastEntry != null ? lastEntry.score : 0;
    }

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const onErrorMessageClosed = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsDoubleOutError(false);
        setInvalidScore(null);
        setErrorMessage(null);
    };

    const styleWonCheckoutModal = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const styleHistoryModal = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 320,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        // p: 4,
    };

    const styleStatisticModal = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 1,
    };

    return (
        <div>
            <Container maxWidth='xl'>
                <Stack
                    margin={-1}
                    direction="row"
                    justifyContent="space-evenly"
                >
                    <Box width={92}></Box>
                    <InputLabel sx={{ padding: "20px", fontSize: "30px" }}>{title()}</InputLabel>
                    <ButtonGroup>
                        <IconButton onClick={() => { setIsHistoryOpen(true) }}>
                            <HistoryIcon
                                className='default-button-color'
                                sx={{ fontSize: 30 }}
                            />
                        </IconButton>
                        <IconButton onClick={() => { setIsStatisticOpen(true) }}>
                            <AnalyticsIcon
                                className='default-button-color'
                                sx={{ fontSize: 30 }}
                            />
                        </IconButton>
                    </ButtonGroup>
                </Stack>
                <Grid
                    container
                    spacing={2}
                    columns={48}
                    justifyContent="center"
                >
                    {
                        players.map(player => {
                            return <StandardGamePlayerComponent
                                active={players.indexOf(player) === playerIndex.current}
                                player={player}
                                lastScore={getLastScore(player)}
                                startingPoints={standardGame.startingPoints}></StandardGamePlayerComponent>
                        })
                    }
                </Grid>
                <Stack
                    margin={1}
                    direction="row"
                    justifyContent="center"
                >
                    <div
                        style={{ width: "68px" }}>
                    </div>
                    <TextField
                        id="current-score-input"
                        inputRef={currentScoreInput}
                        value={currentScore}
                        inputProps={{ style: { textAlign: 'center', fontSize: "64px", width: "200px" } }}
                        variant="standard"
                        type="text"
                        name="score"
                        autoFocus
                        onChange={onScoreChanged}
                        onKeyUp={onScoreEntered}
                        disabled={winner != null}
                    />
                    <IconButton onClick={onUndo}>
                        <UndoIcon sx={{ fontSize: 80, color: '#1976d2' }} />
                    </IconButton>
                </Stack>
                <div>
                    <Modal
                        open={isCheckoutModalOpen && isDoubleOut}
                        onClose={toggleCheckoutModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Fade in={isCheckoutModalOpen && isDoubleOut}>
                            <Box
                                sx={styleWonCheckoutModal}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <StandardGameCheckoutComponent toggle={toggleCheckoutModal} dartsMinimumRequired={dartsMinimumRequired} onConfirm={legFinished} />
                            </Box>

                        </Fade>
                    </Modal>

                </div>
                <div>
                    <Modal
                        open={isWonModalOpen}
                        onClose={toggleWonModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Fade in={isWonModalOpen}>
                            <Box
                                sx={styleWonCheckoutModal}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography id="modal-modal-title" variant="h3">
                                    {winner?.name} won!
                                </Typography>
                            </Box>
                        </Fade>
                    </Modal>
                </div>
                <div>
                    {
                        isDoubleOutError &&
                        <Snackbar open={isDoubleOutError} autoHideDuration={6000} onClose={onErrorMessageClosed}>
                            <Alert onClose={onErrorMessageClosed} severity="error" sx={{ width: '100%', fontSize: 30 }}>
                                Double Out not possible!
                            </Alert>
                        </Snackbar>

                    }
                    {
                        invalidScore != null &&
                        <Snackbar open={invalidScore != null} autoHideDuration={6000} onClose={onErrorMessageClosed}>
                            <Alert sx={{ width: '100%', fontSize: 30 }} severity="error">Score invalid: {invalidScore}</Alert>
                        </Snackbar>
                    }
                    {
                        errorMessage != null &&
                        <Snackbar open={errorMessage != null} autoHideDuration={6000} onClose={onErrorMessageClosed}>
                            <Alert sx={{ width: '100%', fontSize: 30 }} severity="error">{errorMessage}</Alert>
                        </Snackbar>
                    }
                </div>
                <Modal
                    open={isHistoryOpen}
                    onClose={() => setIsHistoryOpen(false)}
                >
                    <Box
                        sx={styleHistoryModal}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Stack
                            display="flex"
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <InputLabel sx={{ fontSize: 25 }}>LEG HISTORY</InputLabel>
                            <HistoryComponent history={history} />
                        </Stack>
                    </Box>
                </Modal>
                <Modal
                    open={isStatisticOpen}
                    onClose={() => setIsStatisticOpen(false)}
                >
                    <Box
                        sx={styleStatisticModal}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <StandardGameStatsComponent players={players} startingPoints={standardGame.startingPoints}></StandardGameStatsComponent>
                    </Box>
                </Modal>
            </Container>
        </div>
    )
}

export default StandardGameComponent
