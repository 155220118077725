import React from 'react'
import { Routes, Route } from 'react-router-dom';
import SettingsComponent from './components/SettingsComponent';
import StandardGameComponent from './components/game/standard/StandardGameComponent';
import AroundTheClockComponent from './components/game/aroundtheclock/AroundTheClockComponent';
import ManagePlayersComponent from './components/playermanagement/ManagePlayersComponent';
import { RecoilRoot } from 'recoil';
import LoginComponent from './components/usermenu/LoginComponent';
import SignUpComponent from './components/usermenu/SignUpComponent';
import HeaderComponent from './components/header/HeaderComponent';

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <HeaderComponent />
        <Routes>
          <Route path="/" element={<SettingsComponent />} />
          <Route path="/game/standard" element={<StandardGameComponent />} />
          <Route path="/game/atc" element={<AroundTheClockComponent />} />
          <Route path="/players" element={<ManagePlayersComponent />} />
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/signup" element={<SignUpComponent />} />
        </Routes>
      </RecoilRoot>
    </div>
  );
}

export default App;

