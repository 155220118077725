import { Box, Button, Fade, Modal, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';


type DialogProps = {
    isOpen: boolean;
    onConfirm: () => void;
    onClose: () => void;
    content: String;
}

const SimpleDialogComponent = ({ isOpen, onClose, onConfirm, content }: DialogProps) => {

    const font_size = 28

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={isOpen}>
                    <Box
                        sx={style}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Typography id="modal-modal-title" variant="h3">
                                {content}
                            </Typography>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Button onClick={onConfirm} style={{ fontSize: font_size, width: 150 }} variant="contained" type="submit">Yes</Button>
                                <Button onClick={onClose} style={{ fontSize: font_size, width: 150 }} variant="outlined">No</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default SimpleDialogComponent