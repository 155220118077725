import DeleteIcon from '@mui/icons-material/Delete';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, Snackbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { darken, lighten } from '@mui/material/styles';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { AxiosError, isAxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import ErrorResponseHandler from '../../helper/ErrorResponseHandler';
import PlayerMasterData from '../../models/PlayerMasterData';
import StandardGameOverallPlayerStats from '../../models/StandardGameOverallPlayerStats';
import AuthService from '../../services/AuthService';
import PlayerService from '../../services/PlayerService';
import StandardGameService from '../../services/StandardGameService';
import StandardGameOverallPlayerStatsComponent from '../game/standard/StandardGameOverallPlayerStatsComponent';
import EnterPlayerMasterDataComponent from './EnterPlayerMasterDataComponent';


const PlayerMasterDataComponent = () => {

    const [playerMasterData, setPlayerMasterData] = useState<PlayerMasterData[]>([]);
    const [isModelDirty, setIsModelDirty] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedPlayers, setSelectedPlayers] = useState<GridSelectionModel>();
    const [wasUpdatePlayersFaulty, setWasUpdatePlayersFaulty] = useState<boolean>();
    const [updatePlayerMessage, setUpdatePlayerMessage] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isRequestInProcess, setIsRequestInProcess] = useState(false);
    const [isInitialLoad, setInitialLoad] = useState(true);
    const [isStatsModalOpen, setIsStatsModalOpen] = useState<boolean>(false);
    const [playerStats, setPlayerStats] = useState<StandardGameOverallPlayerStats>();


    useEffect(() => {
        if (isInitialLoad) {
            setInitialLoad(false);
            loadPlayers();
        }

        if (playerMasterData.find((p) => p.id == null) != null) {
            setIsModelDirty(true);
        }

    }, [playerMasterData]);

    const loadPlayers = async () => {
        try {
            setIsRequestInProcess(true);

            const response = await PlayerService.getAll();
            const playerMasterData: PlayerMasterData[] = response.data;

            setPlayerMasterData(playerMasterData);
        } catch (error: any | AxiosError) {
            ErrorResponseHandler.process(error, "Cannot load players", setErrorMessage, loadPlayers);
        } finally {
            setIsRequestInProcess(false);
        }
    };

    const updatePlayers = async () => {
        try {
            setIsRequestInProcess(true);

            await PlayerService.save(playerMasterData);

            setPlayerMasterData(playerMasterData);
            setUpdatePlayerMessage("Players successfully updated");
            setWasUpdatePlayersFaulty(false);
            setIsModelDirty(false);
        } catch (error: unknown | AxiosError) {
            if (isAxiosError(error)) {
                console.log(error.toJSON());
            }
            setUpdatePlayerMessage("Error during updating players");
            setWasUpdatePlayersFaulty(true);
        } finally {
            loadPlayers();
            setIsRequestInProcess(false);
        }
    }

    const deletePlayers = async (playerIds: string[]) => {
        try {
            setIsRequestInProcess(true);

            await PlayerService.deleteByIds(playerIds);

            setUpdatePlayerMessage("Player successfully deleted.");
            setWasUpdatePlayersFaulty(false);
        } catch (error: unknown | AxiosError) {
            if (isAxiosError(error)) {
                console.log(error.toJSON());
            }
            setUpdatePlayerMessage("Error during deleting the player.");
            setWasUpdatePlayersFaulty(true);
        } finally {
            loadPlayers();
            setIsRequestInProcess(false);
        }
    }

    const onErrorMessageClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setWasUpdatePlayersFaulty(null);
        setErrorMessage(null);
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 50, editable: false, sortable: false },
        { field: 'name', headerName: 'NAME', width: 275, editable: false, sortable: false },
        {
            field: 'actions', headerName: '', width: 55, sortable: false, renderCell: (params) => {
                if (params.row.id == null) {
                    return (
                        <div>
                            <IconButton aria-label="delete" onClick={(e) => onSoftDelete(e, params.row)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <IconButton aria-label="delete" onClick={(e) => onOpenStats(e, params.row)}>
                                <QueryStatsIcon />
                            </IconButton>
                        </div>
                    );
                }
            }
        }
    ];

    const onSoftDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, p: PlayerMasterData) => {
        e.stopPropagation();

        let updatedPlayerMasterData = playerMasterData.filter(player => {
            return player.name !== p.name
        })

        setIsModelDirty(updatedPlayerMasterData.find((p) => p.id == null) != null);

        setPlayerMasterData(updatedPlayerMasterData)
    }

    const onOpenStats = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, p: PlayerMasterData) => {
        e.stopPropagation();

        setIsStatsModalOpen(true);
        findPlayerStats(p);
    }

    const findPlayerStats = async (player: PlayerMasterData) => {
        await StandardGameService.findStats(player.id)
            .then((response) => {
                setPlayerStats(response.data);
                setErrorMessage(null);
            })
            .catch((error) => {
                console.log(error.toJSON());

                if (isAxiosError(error)) {
                    if (error.message === 'Network Error') {
                        closeStatsModal();
                        setErrorMessage("NETWORK ERROR: Cannot provide stats for player " + player.name);
                    }
                    else if (error.response.status === 403) {
                        if (localStorage.getItem("refresh_token") != null) {
                            AuthService.refreshAndSetTokens().then(() => findPlayerStats(player));
                        } else {
                            console.log("Token expired");
                        }
                    }
                }
            })
    }

    const closeStatsModal = () => {
        setIsStatsModalOpen(false);
        setPlayerStats(null);
    }

    const onSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();

        updatePlayers();
    }

    const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();

        const playersToDelete = playerMasterData.filter((p) => selectedPlayers.includes(p.creationTimestamp)).map((p) => p.id);

        deletePlayers(playersToDelete)
        setIsDeleteDialogOpen(false);
    }

    const openDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
    }

    const getBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getHoverBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    return (
        <Stack margin={2}>
            {
                <div>
                    <div>
                        <Stack marginBottom={2}>
                            <EnterPlayerMasterDataComponent players={playerMasterData} setPlayers={setPlayerMasterData} />
                        </Stack>
                    </div>
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            sx={{
                                fontSize: 30,
                                '& .super-app-theme--null': {
                                    bgcolor: (theme) =>
                                        getBackgroundColor(theme.palette.info.main, theme.palette.mode),
                                    '&:hover': {
                                        bgcolor: (theme) =>
                                            getHoverBackgroundColor(
                                                theme.palette.info.main,
                                                theme.palette.mode,
                                            ),
                                    },
                                },
                                "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
                                    display: "none"
                                }
                            }}
                            getRowId={(row) => row?.creationTimestamp}
                            rows={playerMasterData}
                            columns={columns}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'name', sort: 'asc' }],
                                },
                                columns: { columnVisibilityModel: { id: false } }
                            }}
                            // headerHeight={0}
                            rowsPerPageOptions={[3]}
                            pageSize={10}
                            disableSelectionOnClick
                            checkboxSelection
                            onSelectionModelChange={(newSelectedPlayers) => {
                                setSelectedPlayers(newSelectedPlayers);
                            }}
                            // selectionModel={selectedPlayers}
                            disableColumnMenu
                            autoHeight
                            experimentalFeatures={{ newEditingApi: true }}
                            getRowClassName={(params) => `super-app-theme--${params.row.id}`}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        NO PLAYERS AVAILABLE
                                    </Stack>
                                )
                            }}
                        />
                    </div>
                    <Stack margin={2} spacing={2} direction="row" justifyContent="center">
                        <Button
                            disabled={isRequestInProcess || isModelDirty || selectedPlayers == null || selectedPlayers.length === 0}
                            style={{ fontSize: 30, width: 150 }}
                            onClick={openDeleteDialog}
                            variant="contained"
                            type="submit">
                            DELETE
                        </Button>
                        <Button
                            disabled={isRequestInProcess || !isModelDirty}
                            style={{ fontSize: 30, width: 150 }}
                            onClick={onSave}
                            variant="contained"
                            type="submit">
                            SAVE
                        </Button>
                    </Stack>
                    <Dialog open={isDeleteDialogOpen}>
                        <DialogContent>
                            <DialogContentText style={{ fontSize: 30 }} id="alert-dialog-description">
                                Do you really want to delete the player(s)?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button style={{ fontSize: 30, width: 150 }} onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
                            <Button style={{ fontSize: 30, width: 150 }} onClick={onDelete}>Delete</Button>
                        </DialogActions>
                    </Dialog>
                    <StandardGameOverallPlayerStatsComponent stats={playerStats} isModalOpen={isStatsModalOpen} closeModal={closeStatsModal} />
                    <div>
                        {
                            wasUpdatePlayersFaulty &&
                            <Snackbar open={wasUpdatePlayersFaulty} autoHideDuration={6000} onClose={onErrorMessageClose}>
                                <Alert severity={"error"} sx={{ width: '100%', fontSize: 30 }} onClose={onErrorMessageClose}>
                                    {updatePlayerMessage}
                                </Alert>
                            </Snackbar>
                        }
                        {
                            wasUpdatePlayersFaulty != null && !wasUpdatePlayersFaulty &&
                            <Snackbar open={!wasUpdatePlayersFaulty} autoHideDuration={6000} onClose={onErrorMessageClose}>
                                <Alert severity={"success"} sx={{ width: '100%', fontSize: 30 }} onClose={onErrorMessageClose}>
                                    {updatePlayerMessage}
                                </Alert>
                            </Snackbar>
                        }
                        {
                            errorMessage != null &&
                            <Snackbar open={errorMessage != null} autoHideDuration={6000} onClose={onErrorMessageClose}>
                                <Alert sx={{ width: '100%', fontSize: 30 }} severity="error">{errorMessage}</Alert>
                            </Snackbar>
                        }
                    </div>
                </div>
            }
        </Stack >
    )
}

export default PlayerMasterDataComponent