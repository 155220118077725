import { AxiosError, isAxiosError } from "axios";
import AuthService from "../services/AuthService";


class ErrorResponseHandler {

    getErrorMessage(error: any, authenticationErrorMessage: string): string {
        const errors = error as Error | AxiosError;

        if (!isAxiosError(errors)) {
            console.log(errors);
        } else {
            if (errors.response != null && errors.response.status === 403) {
                return authenticationErrorMessage;
            }
            else if (errors.message === 'Network Error') {
                return "Error connecting to the server";
            }
        }

        return "Unknown error: " + error.message;
    }

    process(error: any, errorMessage: string, setErrorMessage: React.Dispatch<React.SetStateAction<string>>, method: () => void): string {
        if (isAxiosError(error)) {
            if (error.message === 'Network Error') {
                setErrorMessage("NETWORK ERROR: " + errorMessage);
            }
            else if (error.response.status === 403) {
                if (localStorage.getItem("refresh_token") != null) {
                    AuthService.refreshAndSetTokens().then(method);
                } else {
                    console.log("Token expired")
                }
            }
        }

        return "Unknown error: " + error.message;
    }
}

export default new ErrorResponseHandler();