import Dart from "./Dart";

class DartsThrow {

    dart1: Dart;
    dart2: Dart;
    dart3: Dart;

    constructor() {
        this.dart1 = null;
        this.dart2 = null;
        this.dart3 = null;
    }
}

export default DartsThrow;