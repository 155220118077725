import { Alert, Snackbar } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import ErrorResponseHandler from '../helper/ErrorResponseHandler';
import Player from '../models/Player';
import PlayerMasterData from '../models/PlayerMasterData';
import PlayerService from '../services/PlayerService';
import { playersState } from './atoms';

const EnterPlayerComponent = () => {

  const [players, setPlayers] = useRecoilState<Player[]>(playersState);
  const [playerMasterData, setPlayerMasterData] = useState<PlayerMasterData[]>();
  const [playerLimitMessage, setPlayerLimitMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    loadPlayers();
  }, []);

  const loadPlayers = async () => {
    await PlayerService.getAll().then((response) => {
      setPlayerMasterData(response.data)
      updateCurrentPlayersIfMasterDataGotRemoved(response.data);
    }).catch((error) => {
      ErrorResponseHandler.process(error, "Cannot load players", setErrorMessage, loadPlayers);
    });
  }

  const onSelectTag = (event: React.SyntheticEvent, value: any) => {
    event.stopPropagation();

    const name = value as string;

    if (players != null && players.length === 6) {
      setPlayerLimitMessage("Max. 6 players allowed")
      return
    }

    const isPlayerAdded = players.find((p) => p.name === name)
    if (!isPlayerAdded) {
      const playerId = playerMasterData.find((p) => p.name === name).id;
      setPlayers(players.concat(new Player(playerId, name, players.length, [])));
    }
  }

  const updateCurrentPlayersIfMasterDataGotRemoved = (newPlayerMasterData: PlayerMasterData[]) => {
    setPlayers(players.filter(currentPlayer => {
      const isPlayerInMasterDataExisting = newPlayerMasterData.find(playerMasterData => {
        return playerMasterData.id === currentPlayer.id;
      })
      if (isPlayerInMasterDataExisting) {
        return currentPlayer;
      }
    }));
  }

  const onUpdatePlayersMessageClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setPlayerLimitMessage(null);
    setErrorMessage(null);
  }

  return (
    <Box
      display="flex"
      sx={{ width: '100%' }}
      alignItems="center"
      justifyContent="center"
      margin={2}
    >
      {
        // playerMasterData != null &&
        <Autocomplete
          disableClearable={true}
          disablePortal
          id="ac-player-master-data"
          options={playerMasterData != null ? playerMasterData.map((option) => option.name) : []}
          renderOption={(props, option) => (
            <Box sx={{ fontSize: 30 }}>
              <li {...props}>{option}</li>
            </Box>
          )}
          onChange={onSelectTag}
          noOptionsText={'No player(s) found'}
          sx={{ width: '100%', fontSize: 30 }}
          renderInput={(params) => {
            return <TextField
              {...params}
              label="SELECT PLAYER"
              InputProps={{
                ...params.InputProps,
                type: 'search',
                style: { fontSize: 30 }
              }}
            />
          }}
        />
      }
      <Snackbar open={playerLimitMessage != null} autoHideDuration={6000} onClose={onUpdatePlayersMessageClose}>
        <Alert severity={"warning"} sx={{ width: '100%', fontSize: 30 }} onClose={onUpdatePlayersMessageClose}>
          {playerLimitMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorMessage != null} autoHideDuration={6000} onClose={onUpdatePlayersMessageClose}>
        <Alert severity={"error"} sx={{ width: '100%', fontSize: 30 }} onClose={onUpdatePlayersMessageClose}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default EnterPlayerComponent