import { Container } from '@mui/system'
import React from 'react'
import PlayerManagementComponent from './PlayerMasterDataComponent'

const ManagePlayersComponent = () => {
    return (
        <div>
            <Container maxWidth='xl'>
                <PlayerManagementComponent />
            </Container>
        </div>
    )
}

export default ManagePlayersComponent