import UndoIcon from '@mui/icons-material/Undo';
import { Container, Grid, IconButton, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import '../../../index.css';
import AroundTheClockLeg from '../../../models/aroundtheclock/AroundTheClockLeg';
import AroundTheClockPlayer from '../../../models/aroundtheclock/AroundTheClockPlayer';
import Dart from '../../../models/common/Dart';
import DartHistoryEntry from '../../../models/common/DartHistoryEntry';
import DartsThrow from '../../../models/common/DartsThrow';
import { FieldType, getMultiplier } from '../../../types/FieldType';
import { aroundTheClockGameState, playersState } from '../../atoms';
import SimpleDialogComponent from '../../SimpleDialogComponent';
import DartboardComponent from '../DartboardComponent';
import AroundTheClockPlayerComponent from './AroundTheClockPlayerComponent';

const AroundTheClockComponent = () => {

    const game = useRecoilValue(aroundTheClockGameState);
    const configuredPlayers = useRecoilValue(playersState);
    const [players, setPlayers] = useState<AroundTheClockPlayer[]>([]);
    const currentPlayerIndex = useRef<number>(0);
    const previousPlayerIndex = useRef<number>(0);
    const [change, setChange] = useState<number>(0);
    const [history, setHistory] = useState<DartHistoryEntry[]>([]);
    const [isFinishedDialogOpen, setIsFinishedDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        setPlayers(configuredPlayers.map((p) => {
            return new AroundTheClockPlayer(p.id, p.name, p.initialStartingOrder, [new AroundTheClockLeg(p.initialStartingOrder === 0)]);
        }));
        currentPlayerIndex.current = 0;
    }, []);

    const onUndo = () => {
        if (history.length === 0) {
            return;
        }

        const lastHistoryEntry = history[history.length - 1];
        const lastPlayer = players.find(p => p.name === lastHistoryEntry.name);
        if (lastPlayer.currentLeg().finished) {
            return;
        }

        let lastDartsThrow = lastPlayer.currentLeg().dartsThrow[lastPlayer.currentLeg().dartsThrow.length - 1];
        if (lastDartsThrow.dart1 == null && lastDartsThrow.dart2 == null && lastDartsThrow.dart3 == null) {
            setPreviousPlayerIndex();
            currentPlayerIndex.current = previousPlayerIndex.current;
        }

        if (lastPlayer.name === currentPlayer().name) {
            undoLastDart(currentThrow(), lastHistoryEntry.dart);
            removeLastHistoryEntry();
            updateChild();
            return;
        }


        setPreviousPlayerIndex();
        currentPlayerIndex.current = previousPlayerIndex.current;
        updateChild();
        onUndo()
    }

    const undoLastDart = (lastDartsThrow: DartsThrow, lastDart: Dart) => {
        if (lastDartsThrow.dart3 != null) {
            undoIfBullseye(lastDartsThrow.dart3)
            lastDartsThrow.dart3 = null;

            if (lastDart.levelUp) {
                currentPlayer().currentLeg().currentScore = lastDart.number
            }
        } else if (lastDartsThrow.dart2 != null) {
            undoIfBullseye(lastDartsThrow.dart2)
            lastDartsThrow.dart2 = null;

            if (lastDart.levelUp) {
                currentPlayer().currentLeg().currentScore = lastDart.number
            }
        } else if (lastDartsThrow.dart1 != null) {
            currentPlayer().currentLeg().dartsThrow.splice(currentPlayer().currentLeg().dartsThrow.length - 1);
            undoIfBullseye(lastDartsThrow.dart1)
            lastDartsThrow.dart1 = null;

            if (lastDart.levelUp) {
                currentPlayer().currentLeg().currentScore = lastDart.number
            }

            // setPreviousPlayerIndex();
            // currentPlayerIndex.current = previousPlayerIndex.current;
        }
    }

    const undoIfBullseye = (dart: Dart) => {
        if (dart.number === 25 && dart.fieldType === FieldType.Single) {
            currentPlayer().currentLeg().amountOfBulls -= 1;
        }
        else if (dart.number === 25 && dart.fieldType === FieldType.Double) {
            currentPlayer().currentLeg().amountOfBulls -= 2;
        }
    }

    const onClose = () => {
        onUndo();
        setIsFinishedDialogOpen(false);
    }

    const scored = (number: number, fieldType: FieldType): void => {
        if (areAllPlacesTaken()) {
            return;
        }

        var showIsFinishedDialog = false;

        if (!currentPlayer().currentLeg()?.finished) {
            if (currentThrow().dart1 != null && currentThrow().dart2 != null && currentThrow().dart3 != null) {
                currentPlayer().currentLeg().dartsThrow.push(new DartsThrow());
            }

            let currentScore = currentPlayer().currentLeg().currentScore;

            if (currentThrow().dart1 == null) {
                currentThrow().dart1 = new Dart(number, fieldType);
                addHistoryEntry(currentPlayer().name, currentThrow().dart1);

                if (currentThrow().dart1.number === currentScore) {
                    let [newScore, isFinished] = calculateNewScore(currentScore, currentThrow().dart1);
                    showIsFinishedDialog = isFinished;
                    currentPlayer().currentLeg().currentScore = newScore;
                    currentThrow().dart1.levelUp = true;
                }
            }
            else if (currentThrow().dart2 == null) {
                currentThrow().dart2 = new Dart(number, fieldType);
                addHistoryEntry(currentPlayer().name, currentThrow().dart2);

                if (currentThrow().dart2.number === currentScore) {
                    let [newScore, isFinished] = calculateNewScore(currentScore, currentThrow().dart2);
                    showIsFinishedDialog = isFinished;
                    currentPlayer().currentLeg().currentScore = newScore;
                    currentThrow().dart2.levelUp = true;
                }
            }
            else if (currentThrow().dart3 == null) {
                currentThrow().dart3 = new Dart(number, fieldType);
                addHistoryEntry(currentPlayer().name, currentThrow().dart3);

                if (currentThrow().dart3.number === currentScore) {
                    let [newScore, isFinished] = calculateNewScore(currentScore, currentThrow().dart3);
                    showIsFinishedDialog = isFinished;
                    currentPlayer().currentLeg().currentScore = newScore;
                    currentThrow().dart3.levelUp = true;
                }

                if (isMultiplayer() && !showIsFinishedDialog) {
                    setNextPlayer();
                }
            }
        }

        if (isMultiplayer()) {
            if (areAllPlacesTaken()) {
                // Multiplayer game over
                updateChild();
                return;
            } else {
                if (currentPlayer().currentLeg().finished) {
                    setNextPlayer();
                }
            }
        }

        if (showIsFinishedDialog) {
            setIsFinishedDialogOpen(true)
        } else {
            updateChild();
        }
    }

    const areAllPlacesTaken = (): boolean => {
        if (isMultiplayer()) {
            let finishedPlayers = players.filter(p => p.currentLeg().finished)
            return finishedPlayers.length + 1 >= players.length;
        } else {
            return currentPlayer()?.currentLeg()?.finished;
        }
    }

    /**
     * @returns 
     *      number => the new score of the current player
     *      boolean => indicates if the current player is finished
     */
    const calculateNewScore = (currentScore: number, dart: Dart): [number, boolean] => {
        let multiplier = getMultiplier(dart.fieldType);
        if (currentScore === 25 && currentPlayer().currentLeg().amountOfBulls === 1) {
            currentPlayer().currentLeg().amountOfBulls = 2;
            return [25, true];
        } else if (currentScore === 25 && multiplier === 2) {
            currentPlayer().currentLeg().amountOfBulls = 2;
            return [25, true];
        } else if (currentScore === 25 && multiplier === 1) {
            currentPlayer().currentLeg().amountOfBulls = 1;
            return [25, false];
        } else if (currentScore + multiplier >= 21) {
            return [25, false];
        } else {
            return [currentScore + multiplier, false];
        }
    }

    const finishLegForCurrentPlayer = () => {
        currentPlayer().currentLeg().finished = true;
        let finishedPlayers = players.filter(p => p.currentLeg().finished);
        currentPlayer().currentLeg().position = finishedPlayers.length;
        updateChild();
        setIsFinishedDialogOpen(false);
        setNextPlayer();
    }

    const setNextPlayer = () => {
        if (players.length > 1) {
            const newPlayerIndex = (currentPlayerIndex.current + 1) % players.length;

            currentPlayerIndex.current = newPlayerIndex;

            if (players[newPlayerIndex].legs[players[newPlayerIndex].legs.length - 1].finished) {
                setNextPlayer();
            }
        }
    }

    const setPreviousPlayerIndex = () => {
        if (players.length === 1) {
            // Index always 0 if only one player.
            previousPlayerIndex.current = 0;
        } else {
            // Take the current index minus 1.
            // If value is negative start with last player in the list.
            let index = currentPlayerIndex.current - 1;
            if (index < 0) {
                index = players.length - 1;
            }

            let dartsThrow = players[index].currentLeg()?.dartsThrow;
            if (dartsThrow == null || dartsThrow[0] == null || dartsThrow[0].dart1 == null) {
                return;
            }

            previousPlayerIndex.current = index;

            // Repeat if the selected player has already finished the current leg.
            if (players[previousPlayerIndex.current].currentLeg().finished) {
                currentPlayerIndex.current = index;
                setPreviousPlayerIndex();
            }
        }
    }

    const addHistoryEntry = (name: string, dart: Dart) => {
        const entry: DartHistoryEntry = {
            id: history.length,
            name: name,
            dart: dart
        }

        setHistory(history.concat(entry));
    }

    const removeLastHistoryEntry = () => {
        setHistory(history.filter((entry, index) => {
            if (index !== history.length - 1) {
                return entry;
            }
        }))
    }

    const isMultiplayer = (): boolean => {
        return players.length > 1;
    }

    const currentPlayer = (): AroundTheClockPlayer => {
        return players[currentPlayerIndex.current]
    }

    const currentThrow = (): DartsThrow => {
        return currentPlayer()?.currentLeg()?.currentThrow()
    }

    const updateChild = () => {
        setChange(change + 1);
    }

    return (
        <div className="custom-cursor">
            <Container maxWidth='xl'>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                >
                    <DartboardComponent scored={scored} />
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                    >
                        <Grid
                            container
                            // spacing={2}
                            columns={48}
                            justifyContent="center"
                        >
                            {
                                players.map(player => {
                                    return <AroundTheClockPlayerComponent
                                        active={players.indexOf(player) === currentPlayerIndex.current}
                                        dartsThrow={player.currentLeg().currentThrow()}
                                        player={player}
                                    />
                                })
                            }
                        </Grid>
                        <IconButton disabled={areAllPlacesTaken()} style={{ maxWidth: 200 }} onClick={onUndo}>
                            <UndoIcon sx={{ fontSize: 80, color: '#1976d2' }} />
                        </IconButton>
                    </Stack>
                </Stack>
            </Container>
            <SimpleDialogComponent isOpen={isFinishedDialogOpen} onConfirm={finishLegForCurrentPlayer} onClose={() => onClose()} content={currentPlayer()?.name + " finished?"} />
        </div>
    )
}

export default AroundTheClockComponent