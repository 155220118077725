export enum FieldType {
    Single = "S",
    Double = "D",
    Triple = "T",
    Surround = ""
}

export const getMultiplier = (fieldType: FieldType): number => {
    if (fieldType === FieldType.Single){
        return 1;
    } else if (fieldType === FieldType.Double) {
        return 2;
    } else if (fieldType === FieldType.Triple) {
        return 3;
    } else {
        return 0;
    }
}