import { GameSettings } from "./GameSettings";
import { CheckoutMode } from "../types/CheckoutMode";
import { GameMode } from "../types/GameMode";


export class StandardGame extends GameSettings {

    // Defines the starting points which must be played to zero
    startingPoints: number;
    // Defines how to play the last number
    checkoutMode: CheckoutMode;
    // Indicates if it's played in set or leg mode
    gameMode: GameMode;
    // Indicates how many legs are played
    legs: number;
    // Indicates how many sets are played
    sets: number;


    constructor(rankingMode: boolean, startingPoints: number, checkoutMode: CheckoutMode, gameMode: GameMode, legs: number, sets: number) {
        super(rankingMode);
        this.startingPoints = startingPoints;
        this.checkoutMode = checkoutMode;
        this.gameMode = gameMode;
        this.legs = legs;
        this.sets = sets;
    }
}